import React from "react";
import moment from "moment";

import "./Basal.css";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import Header from "../../shared/components/layout/Header";
import GeneralData from "../components/GeneralData";
import { Collapse, Form, Modal } from "antd";
import SocioEconomico from "../components/SocioEconomico";
import CriterioDeInclusion from "../components/CriterioDeInclusion";
import EtiologiaIC from "../components/EtilogiaIC";
import AntecedentesMedicos from "../components/AntecedentesMedicos";
import DispotivoImplantado from "../components/DispositivoImplantado";
import Sintomas from "../components/Sintomas";
import ExploracionFisica from "../../shared/ExploracionFisica/ExploracionFisica";
import Medicamentos from "../../shared/medicamento/pages/Medicamentos";
import EstudiosDeGabinete from "../../shared/EstudiosDeGabinete/EstudiosDeGabinete";
import EstudiosDeLaboratorios from "../../shared/EstudiosDeLaboratorio/EstudiosDeLaboratorios";
import withParams from "./../../hooks/withParams";
import { onChangeNum } from "../../utils/onChangeNum";
import { cmToMeters } from "../../utils/conversions";

const { Panel } = Collapse;

class Basal extends React.Component {
  constructor() {
    super();
    this.form = "basal";
    this.ref = React.createRef();
    this.state = {
      id_paciente: "",
      //Datos Generales
      origen: "",
      cedula: "",
      MedicoReferente: "",
      MedicoTratante: "",
      nacimiento: "",
      edad: "",
      sexo: "",
      raza: "",
      //Socio Economico
      escolaridad: "",
      redDeApoyo: "",
      soporteDeApoyo: "",
      gastosMedicos: "",
      vivienda: "",
      aportaEconomicamente: "",
      rehabilitacion: "",
      cuidadosPaliativos: "",
      //Criterio De Inclusion
      fechaDeDiagnosticoIC: "",
      tipoDeIC: "",
      clasificacionDeIC: "",
      etapaACCAHC: "",
      //etiologia
      abusoDeSustanciasToxicasDeUsoRecreativoCheck: false,
      metalesPesadosCheck: false,
      medicamentosCheck: false,
      radiacionCheck: false,
      enfermedadDeChagasCheck: false,
      enfermedadDeTiroidesYParatiroidesCheck: false,
      diabetesCheck: false,
      comunicacionInterauricularOInterventricularCheck: false,
      pericarditisConstrictivaCheck: false,
      arritmiasVentricularesCheck: false,
      disfuncionesDelNoduloSinusalCheck: false,
      arritmiasAuricularesCheck: false,
      enfermedadesRelacionadasConLaGestacionYElPeripartoCheck: false,
      deficienciasEnCarnitinaLCheck: false,
      MCHCheck: false,
      MCDCheck: false,
      derramePericardicoCheck: false,
      SHECheck: false,
      FEMCheck: false,
      fibroelastosisEndomiocardicaCheck: false,
      VINoCompactadoCheck: false,
      MAVDCheck: false,
      miocardiopatiaRestrictivaCheck: false,
      valvulopatiaMitralCheck: false,
      valvulopatiaAorticaCheck: false,
      valvulopatiaTricuspideaCheck: false,
      valvulopatiaPulmonarCheck: false,
      otroEtiologiaCheck: false,
      otroEtiologia: "",
      //Antecedentes Medicos
      hipertensionCheck: false,
      diabetesAntecedentesMedicosCheck: false,
      disfuncionRenalCheck: false,
      tabaquismoCheck: false,
      alcoholismoCheck: false,
      infartoAlMiocardioPrevioCheck: false,
      fibrilacionAtrialCheck: false,
      enfermedadPulmonarCronicaCheck: false,
      disfuncionErectilCheck: false,
      artritisReumatoideCheck: false,
      gotaCheck: false,
      anemiaCheck: false,
      trastornosDelSueñoCheck: false,
      hipertiroidismoCheck: false,
      hipotiroidismoCheck: false,
      cancerCheck: false,
      VIHCheck: false,
      //dispotivo implantado
      fechaDeImplante: "",
      dispositivoImplantado: "",
      //Sintomas

      sintomasDisabled: false,
      disneaCheck: false,
      ortopneaCheck: false,
      disneaParoxisticaNocturnaCheck: false,
      toleranciaAlEjercicioDisminuidaCheck: false,
      fatigaCansancioMasTiempoHastaRecuperarseDelEjercicioCheck: false,
      edemaDeTobillosCheck: false,
      tosNocturnaCheck: false,
      sibilanciasCheck: false,
      sensacionDeHinchazonCheck: false,
      perdidaDeApetitoCheck: false,
      confusionCheck: false,
      decaimientoCheck: false,
      palpitacionesCheck: false,
      mareoCheck: false,
      sincopeCheck: false,
      bendopneaCheck: false,
      anginaCheck: false,
      //ExploracionFisica
      frecuenciaCardiaca: "",
      TASistolica: "",
      TADiastolica: "",
      peso: "",
      talla: "",
      IMC: "",
      superficieCorporal: "",
      pletoraYugular: "",
      reflujoHepatoyugularCheck: false,
      tercerSonidoCardiacoCheck: false,
      impulsoApicalDesplazadoLateralmenteCheck: false,
      cuartoRuidoCheck: false,
      estertoresCrepitantesCheck: false,
      hepatomegaliaCheck: false,
      otroExploracionFisicaCheck: false,
      otroExploracionFisica: "",
      //Medicamentos
      captoprilCheck: false,
      captopril: "",
      captoprilDate: "",
      enalaprilCheck: false,
      enalapril: "",
      enalaprilDate: "",
      lisinoprilCheck: false,
      lisinopril: false,
      lisinoprilDate: "",
      ramiprilCheck: false,
      ramipril: false,
      ramiprilDate: "",
      trandolaprilaCheck: false,
      trandolaprila: false,
      trandolaprilaDate: "",
      bisoprololCheck: false,
      bisoprolol: false,
      bisoprololDate: "",
      carveilolCheck: false,
      carvedilol: false,
      carvedilolDate: "",
      succinatoDeMetoprololCheck: false,
      succinatoDeMetoprolol: false,
      succinatoDeMetoprololDate: "",
      nebivololCheck: false,
      nebivolol: false,
      nebivololDate: "",
      tartratoDeMetoprololCheck: false,
      tartratoDeMetoprolol: false,
      tartratoDeMetoprololDate: "",
      candersartanCheck: false,
      candersartan: false,
      candersartanDate: "",
      valsartanCheck: false,
      valsartan: false,
      valsartanDate: "",
      losartanCheck: false,
      losartan: false,
      losartanDate: "",
      telmisartanCheck: false,
      telmisartan: false,
      telmisartanDate: "",
      eplerenonaCheck: false,
      eplerenona: false,
      eplerenonaDate: "",
      espironolactonaCheck: false,
      espironolactona: false,
      espironolactonaDate: "",
      sacubitriloValsartanCheck: false,
      sacubitriloValsartan: false,
      sacubitriloValsartanDate: "",
      ivabradinaCheck: false,
      ivabradina: false,
      ivabradinaDate: "",
      furosemidaOralCheck: false,
      furosemidaOral: false,
      furosemidaOralDate: "",
      furosemidaIVCheck: false,
      furosemidaIV: false,
      furosemidaIVDate: "",
      bumetanidaCheck: false,
      bumetanida: false,
      bumetanidaDate: "",
      hidroclorotiazidaCheck: false,
      hidroclorotiazida: false,
      hidroclorotiazidaDate: "",
      clortalidonaCheck: false,
      clortalidona: false,
      clortalidonaDate: "",
      indapamidaCheck: false,
      indapamida: false,
      indapamidaDate: "",
      espironolactonaEplerrenonaCheck: false,
      espironolactonaEplerrenona: false,
      espironolactonaEplerrenonaDate: "",
      amiloridaCheck: false,
      amilorida: false,
      amiloridaDate: "",
      triamterenoCheck: false,
      triamtereno: false,
      triamterenoDate: "",
      digoxinaCheck: false,
      digoxina: false,
      digoxinaDate: "",
      hidralazinaCheck: false,
      hidralazina: false,
      hidralazinaDate: "",
      nitroglicerinaCheck: false,
      nitroglicerina: false,
      nitroglicerinaDate: "",
      dinitratoDeIsosorbidaCheck: false,
      dinitratoDeIsosorbida: false,
      dinitratoDeIsosorbidaDate: "",
      nitroprusiatoCheck: false,
      nitroprusiato: false,
      nitroprusiatoDate: "",
      nesiritidaCheck: false,
      nesiritida: false,
      nesiritidaDate: "",
      noradrenalinaCheck: false,
      noradrenalina: false,
      noradrenalinaDate: "",
      epinefrinaCheck: false,
      epinefrina: false,
      epinefrinaDate: "",
      dobutaminaCheck: false,
      dobutamina: false,
      dobutaminaDate: "",
      dopaminaCheck: false,
      dopamina: false,
      dopaminaDate: "",
      levosimendanCheck: false,
      levosimendan: false,
      levosimendanDate: "",
      inhibidoresDeLaFosfodiesterasaIIICheck: false,
      inhibidoresDeLaFosfodiesterasaIII: false,
      inhibidoresDeLaFosfodiesterasaIIIDate: "",
      vericiguatCheck: false,
      vericiguat: false,
      vericiguatDate: "",
      omencativMercarbilCheck: false,
      omencativMercarbil: false,
      omencativMercarbilDate: "",
      dapagliflozinaCheck: false,
      dapagliflozina: false,
      dapagliflozinaDate: "",
      empagliflozinaCheck: false,
      empagliflozina: false,
      empagliflozinaDate: "",
      estatinasCheck: false,
      estatinas: false,
      estatinasDate: "",
      anticoagulantesOralesCheck: false,
      anticoagulantesOrales: false,
      anticoagulantesOralesDate: "",
      antiagregantesPlaquetariosCheck: false,
      antiagregantesPlaquetarios: false,
      antiagregantesPlaquetariosDate: "",
      AINESCheck: false,
      AINES: false,
      AINESDate: "",
      //Estudios de gabinete
      radiografiaDeToraxCheck: false,
      radioTorax: "",
      ECOTranstoracicoCheck: false,
      FEVI: "",
      RelacionEA: "",
      disfuncionDiastolicaSistolicaCheck: false,
      disfuncionDiastolica: "",
      enfermedadValvularCheck: false,
      tipoEnfermedadValvular: "",
      funcionDeVD: "",
      presionSistolica: "",
      volAuricular: "",
      ecocardiografiaTransesofagicaCheck: false,
      ecocardiografiaDeEstresCheck: false,
      resonanciaMagneticaCheck: false,
      angiografiaCoronariaCheck: false,
      tomografiaComputarizadaCardiacaCheck: false,
      holterCheck: false,
      pruebaDeEsfuerzoCheck: false,
      electrocardiogramaQRSCheck: false,
      QRSElectrocardiograma: "",
      radioFa: false,
      // Estudios de laboratorio
      hemoglobina: "",
      leucocitos: "",
      sodio: "",
      potasio: "",
      urea: "",
      creatinina: "",
      TFGe: "",
      funcionHepatica: "",
      glucosa: "",
      HbA1c: "",
      perfilLipidico: "",
      TSH: "",
      ferritinaTSATTIBC: "",
      BNP: "",
      NTproBNP: "",
      cloro: "",
    };
  }

  componentDidMount = async () => {
    let basal = await fetch(
      `https://ximet.herokuapp.com/api/v1/basal/${this.props.params.id_paciente}`
    )
      .then((res) => res.json())
      .then((json) => json.data.basal);

    Object.keys(basal).forEach((key) => {
      if (
        (key.includes("Date") ||
          key.includes("date") ||
          key.includes("fecha") ||
          key.includes("nacimiento")) &&
        basal[key] !== null
      ) {
        basal[key] = moment(basal[key]);
      }
    });

    this.ref.current.setFieldsValue({
      ...basal,
    });

    this.setState(basal, () => console.log(this.state));
  };

  onChangeField = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      console.log(this.state)
    );
  };

  onChangeDate = (date, dateString, id) => {
    this.setState({
      [id]: moment(dateString),
    });
  };

  onChangeCheck = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.checked,
      },
      console.log(e.target.name, e.target.checked)
    );
  };

  onChangeNumber = (e) => {
    onChangeNum(this, e);
  };

  onToggleSintomas = (e) => {
    console.log(e);
    this.setState(
      {
        noTieneSintomas: e,
        disneaCheck: false,
        ortopneaCheck: false,
        disneaParoxisticaNocturnaCheck: false,
        toleranciaAlEjercicioDisminuidaCheck: false,
        fatigaCansancioMasTiempoHastaRecuperarseDelEjercicioCheck: false,
        edemaDeTobillosCheck: false,
        tosNocturnaCheck: false,
        sibilanciasCheck: false,
        sensacionDeHinchazonCheck: false,
        perdidaDeApetitoCheck: false,
        confusionCheck: false,
        decaimientoCheck: false,
        palpitacionesCheck: false,
        mareoCheck: false,
        sincopeCheck: false,
        bendopneaCheck: false,
        anginaCheck: false,
      },
      () => console.log(this.state)
    );
    this.ref.current.setFieldsValue({
      //...this.state,
      noTieneSintomas: e,
      disneaCheck: false,
      ortopneaCheck: false,
      disneaParoxisticaNocturnaCheck: false,
      toleranciaAlEjercicioDisminuidaCheck: false,
      fatigaCansancioMasTiempoHastaRecuperarseDelEjercicioCheck: false,
      edemaDeTobillosCheck: false,
      tosNocturnaCheck: false,
      sibilanciasCheck: false,
      sensacionDeHinchazonCheck: false,
      perdidaDeApetitoCheck: false,
      confusionCheck: false,
      decaimientoCheck: false,
      palpitacionesCheck: false,
      mareoCheck: false,
      sincopeCheck: false,
      bendopneaCheck: false,
      anginaCheck: false,
    });
  };

  onChangeNacimiento = (date, dateString, id) => {
    const nacimiento = new Date(dateString).getTime();
    const currentDate = new Date().getTime();
    const edad = Math.floor(
      (currentDate - nacimiento) / (1000 * 60 * 60 * 24 * 365)
    );
    this.setState({
      [id]: dateString,
      edad: edad,
    });
    this.ref.current.setFieldsValue({
      edad: edad,
    });
  };

  onChangeCascader = (array) => {
    this.setState({
      dispositivoImplantado: array[0],
      dispositivoImplantadoAnexo: array[1],
    });
  };

  onFinish = async () => {
    const response = await fetch(
      `https://ximet.herokuapp.com/api/v1/basal/${this.props.params.id_paciente}`,
      {
        method: "PUT",
        body: JSON.stringify(this.state),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        res.json();
      })
      .then((json) => json)
      .catch((err) => console.log(err));

    Modal.success({
      title: "Has guardado correctamente!",
    });
  };

  showWarningModal = () => {
    const clearAll = () => {
      let newState = {};
      Object.keys(this.state).forEach((key) => {
        newState[key] = null;
        if (key === "id_paciente" || key === "id") {
          newState[key] = this.state[key];
        }
      });
      this.setState(newState, () => console.log(this.state));
      this.ref.current.resetFields();
      this.ref.current.setFieldsValue({
        id_paciente: this.props.params.id_paciente,
        id: this.state.id,
      });
    };

    Modal.confirm({
      title: "Espera!",
      icon: <ExclamationCircleOutlined />,
      content: `Seguro que quieres borrar todos los campos?`,
      okText: "Borrar",
      cancelText: "Cancelar",
      onOk() {
        return clearAll();
      },
      onCancel() {
        return;
      },
    });
  };

  render() {
    const {
      onChangeDate,
      onChangeField,
      onChangeCheck,
      onChangeNumber,
      onFinish,
      onChangeNacimiento,
      onToggleSintomas,
      showWarningModal,
      onChangeCascader,
    } = this;
    return (
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        ref={this.ref}
        onFinish={onFinish}
      >
        <Header
          state={this.state}
          formType={this.form}
          id_paciente={this.props.params.id_paciente}
          clearAll={showWarningModal}
        />
        <GeneralData
          onChangeNacimiento={onChangeNacimiento}
          onChangeField={onChangeField}
          onChangeDate={onChangeDate}
          onChangeNumber={onChangeNumber}
          state={this.state}
        />
        <Collapse bordered={false} defaultActiveKey={[""]}>
          <Panel
            forceRender={true}
            className="panel"
            header="Socio económico"
            key="1"
          >
            <SocioEconomico onChangeField={onChangeField} />
          </Panel>
          <Panel forceRender={true} header="Criterio de inclusión" key="2">
            <CriterioDeInclusion
              onChangeField={onChangeField}
              onChangeDate={onChangeDate}
            />
          </Panel>
          <Panel forceRender={true} header="Etiología IC" key="3">
            <EtiologiaIC
              onChangeField={onChangeField}
              onChangeCheck={onChangeCheck}
              state={this.state}
            />
          </Panel>
          <Panel forceRender={true} header="Antecedentes médicos" key="4">
            <AntecedentesMedicos
              onChangeField={onChangeField}
              onChangeCheck={onChangeCheck}
              onChangeNumber={onChangeNumber}
              state={this.state}
            />
          </Panel>
          <Panel forceRender={true} header="Dispositivo implantado" key="5">
            <DispotivoImplantado
              onChangeField={onChangeField}
              onChangeDate={onChangeDate}
              onChangeNumber={onChangeNumber}
              onChangeCascader={onChangeCascader}
              state={this.state}
            />
          </Panel>
          <Panel forceRender={true} header="Síntomas" key="6">
            <Sintomas
              onToggleSintomas={onToggleSintomas}
              onChangeField={onChangeField}
              onChangeCheck={onChangeCheck}
              onChangeNumber={onChangeNumber}
              state={this.state}
            />
          </Panel>
          <Panel forceRender={true} header="Exploracion física" key="7">
            <ExploracionFisica
              onChangeField={onChangeField}
              onChangeCheck={onChangeCheck}
              onChangeNumber={onChangeNumber}
              state={this.state}
            />
          </Panel>
          <Panel forceRender={true} Col header="Medicamentos" key="8">
            <Medicamentos
              onChangeField={onChangeField}
              onChangeDate={onChangeDate}
              onChangeCheck={onChangeCheck}
              onChangeNumber={onChangeNumber}
              state={this.state}
            />
          </Panel>
          <Panel forceRender={true} Col header="Estudios de gabinete" key="9">
            <EstudiosDeGabinete
              onChangeField={onChangeField}
              onChangeDate={onChangeDate}
              onChangeCheck={onChangeCheck}
              onChangeNumber={onChangeNumber}
              state={this.state}
            />
          </Panel>
          <Panel
            forceRender={true}
            Col
            header="Estudios de laboratorios"
            key="10"
          >
            <EstudiosDeLaboratorios
              onChangeField={onChangeField}
              onChangeNumber={onChangeNumber}
              state={this.state}
            />
          </Panel>
        </Collapse>
        <button id={this.form} type="submit" hidden={true}>
          SUBMIT
        </button>
      </Form>
    );
  }
}

export default withParams(Basal);
