import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const BloqueadoresDelCanalIF = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Ivabradina"
                checked={state.ivabradinaAnteriorCheck}
                input={state.ivabradinaAnterior}
                date={state.ivabradinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="ivabradinaAnterior"
                nameCheck="ivabradinaAnteriorCheck"
                nameDate="ivabradinaAnteriorDate"
            />
        </>
    );
};

export default BloqueadoresDelCanalIF;