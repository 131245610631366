import React from "react";

import { Button, Divider } from "antd";
import {
  SaveOutlined,
  FileExcelFilled,
  FileTextFilled,
  ClearOutlined,
} from "@ant-design/icons";

import "./Header.css";

const Header = ({ formType, state, id_paciente, formID, clearAll }) => {
  return (
    <>
      <div className="Header">
        <h1>{state.id_paciente}</h1>
        <div>
          <Button
            className="button"
            type="primary"
            onClick={() => document.getElementById(formType).click()}
          >
            {" "}
            <SaveOutlined /> Guardar
          </Button>
          <Button
            className="button"
            type="primary"
            ghost
            onClick={() => {
              if (formID) {
                window.location.href = `https://ximet.herokuapp.com/api/v1/${formType}/excel/${id_paciente}/${formID}`;
              } else {
                window.location.href = `https://ximet.herokuapp.com/api/v1/${formType}/excel/${id_paciente}`;
              }
            }}
            style={{
              background: "#a0db8e",
              borderColor: "#a0db8e",
              color: "white",
            }}
          >
            {" "}
            <FileExcelFilled /> Excel{" "}
          </Button>
          <Button
            className="button"
            type="primary"
            ghost
            onClick={() => {
              if (formID) {
                window.location.href = `https://ximet.herokuapp.com/api/v1/${formType}/txt/${id_paciente}/${formID}`;
              } else {
                window.location.href = `https://ximet.herokuapp.com/api/v1/${formType}/txt/${id_paciente}`;
              }
            }}
            style={{
              background: "white",
              borderColor: "white",
            }}
          >
            {" "}
            <FileTextFilled /> TXT{" "}
          </Button>
          <Button
            className="button"
            type="primary"
            ghost
            onClick={() => {
              clearAll();
            }}
            style={{
              background: "#c7063b",
              borderColor: "#c7063b",
              color: "white",
            }}
          >
            {" "}
            <ClearOutlined /> Limpiar todo{" "}
          </Button>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default Header;
