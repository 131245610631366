import React from "react";
import { Form, Input, Checkbox, Radio } from "antd";

import { ruleNumber } from "../../utils/rules";

function EstudiosDeGabinete({
  onChangeField,
  onChangeCheck,
  onChangeNumber,
  state,
}) {
  return (
    <div>
      <Form.Item label="Radiografía de Tórax" name="radiografiaDeToraxCheck">
        <Checkbox
          onChange={onChangeCheck}
          checked={state.radiografiaDeToraxCheck}
          name="radiografiaDeToraxCheck"
        />
      </Form.Item>

      <Form.Item label="|" name="radioTorax">
        <Radio.Group
          name="radioTorax"
          onChange={onChangeField}
          disabled={!state.radiografiaDeToraxCheck}
        >
          <Radio value="Derrame pleural">Derrame pleural</Radio>
          <Radio value="Cardiomegalia">Cardiomegalia</Radio>
          <Radio value="Descripcion de arteria pulmonar">
            Descripción de arteria pulmonar
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="ECO transtorácico" name="ECOTranstoracicoCheck">
        <Checkbox
          name="ECOTranstoracicoCheck"
          checked={state.ECOTranstoracicoCheck}
          onChange={onChangeCheck}
        />
      </Form.Item>
      <div>
        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>FEVI (%)</div>
            <Form.Item
              name="FEVI"
              wrapperCol={{ span: 24 }}
              rules={ruleNumber}
              validateTrigger="onChange"
            >
              <Input
                name="FEVI"
                onChange={(e) => {
                  onChangeNumber(e);
                }}
                disabled={!state.ECOTranstoracicoCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>Relación E/A</div>
            <Form.Item
              name="RelacionEA"
              wrapperCol={{ span: 24 }}
              rules={ruleNumber}
              validateTrigger="onChange"
            >
              <Input
                name="RelacionEA"
                onChange={(e) => onChangeNumber(e)}
                disabled={!state.ECOTranstoracicoCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="|">
          <Checkbox
            checked={state.disfuncionDiastolicaSistolicaCheck}
            onChange={onChangeCheck}
            disabled={!state.ECOTranstoracicoCheck}
            name="disfuncionDiastolicaSistolicaCheck"
          >
            Disfunción diastólica sistólica
          </Checkbox>
        </Form.Item>
        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>Diámetro diastólico</div>
            <Form.Item name="disfuncionDiastolica">
              <Input
                minLength={3}
                name="disfuncionDiastolica"
                onChange={(value) =>
                  onChangeNumber(value, "disfuncionDiastolica")
                }
                disabled={!state.disfuncionDiastolicaSistolicaCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="|">
          <Checkbox
            checked={state.enfermedadValvularCheck}
            onChange={onChangeCheck}
            disabled={!state.ECOTranstoracicoCheck}
            name="enfermedadValvularCheck"
          >
            Enfermedad valvular
          </Checkbox>
        </Form.Item>
        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>Tipo</div>
            <Form.Item wrapperCol={{ span: 24 }} name="tipoEnfermedadValvular">
              <Input
                name="tipoEnfermedadValvular"
                onChange={onChangeField}
                disabled={!state.enfermedadValvularCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>Función de VD</div>
            <Form.Item wrapperCol={{ span: 24 }} name="funcionDeVD">
              <Input
                name="funcionDeVD"
                onChange={onChangeField}
                disabled={!state.enfermedadValvularCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>Presión sistólica</div>
            <Form.Item wrapperCol={{ span: 24 }} name="presionSistolica">
              <Input
                name="presionSistolica"
                onChange={onChangeField}
                disabled={!state.enfermedadValvularCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="|">
          <Input.Group compact>
            <div style={{ width: "25%" }}>Vol auricular</div>
            <Form.Item wrapperCol={{ span: 24 }} name="volAuricular">
              <Input
                name="volAuricular"
                onChange={onChangeField}
                disabled={!state.enfermedadValvularCheck}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </div>
    </div>
  );
}

export default EstudiosDeGabinete;
