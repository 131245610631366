import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const DeletePacienteModal = ({
  isDeleteModalVisible,
  setIsDeleteModalVisible,
  currentDeletePaciente,
  getPatients,
}) => {
  const handleDeletePaciente = async () => {
    await fetch(
      `
      https://ximet.herokuapp.com/api/v1/patient/${currentDeletePaciente}`,
      {
        method: "DELETE",
      }
    );
    await getPatients();
    return setIsDeleteModalVisible(false);
  };
  return (
    <Modal
      centered={true}
      title={
        <div>
          <ExclamationCircleOutlined style={{ color: "red" }} /> "Espera!!!"
        </div>
      }
      onOk={async () => {
        await new Promise(() => {
          handleDeletePaciente();
        }).catch(() => console.log("Oops errors!"));
      }}
      onCancel={() => {
        setIsDeleteModalVisible(false);
      }}
      okText="Borrar"
      cancelText="Cancelar"
      okType="danger"
      visible={isDeleteModalVisible}
    >
      Seguro que quieres borrar el paciente con numero:{" "}
      <b>{currentDeletePaciente}</b>
    </Modal>
  );
};

export default DeletePacienteModal;
