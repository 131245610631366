import React from "react";

import { Table, Radio, Form, InputNumber } from "antd";

function Cuestionario({ onChangeField, onChangeNumber, onChangeSum, state }) {
  const onChangeScore = async (e) => {
    await onChangeField(e);
    await onChangeSum();
  };

  const columns = [
    {
      title:
        "Su Insuficiencia cardiaca le impidió vivir como hubiese querido durante el último mes por: ",
      dataIndex: "name",
      key: "name",
      render: (question) => <b>{question}</b>,
    },
    {
      title: "No aplica",
      dataIndex: "valorCero",
      key: "valorCero",
      render: (name) => {
        return (
          <Form.Item name={name}>
            <Radio.Group name={name} onChange={onChangeScore}>
              <Radio value={0}>0</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: "Muy Poco",
      dataIndex: "valorUno",
      key: "valorUno",
      render: (name) => {
        return (
          <Form.Item name={name}>
            <Radio.Group name={name} onChange={onChangeScore}>
              <Radio value={1}>1</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: "Poco",
      dataIndex: "valorDos",
      key: "valorDos",
      render: (name) => {
        return (
          <Form.Item name={name}>
            <Radio.Group name={name} onChange={onChangeScore}>
              <Radio value={2}>2</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: "Regular",
      dataIndex: "valorTres",
      key: "valorTres",
      render: (name) => {
        return (
          <Form.Item name={name}>
            <Radio.Group name={name} onChange={onChangeScore}>
              <Radio value={3}>3</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: "Mucho",
      dataIndex: "valorCuatro",
      key: "valorCuatro",
      render: (name) => {
        return (
          <Form.Item name={name}>
            <Radio.Group name={name} onChange={onChangeScore}>
              <Radio value={4}>4</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: "Demasiado",
      dataIndex: "valorCinco",
      key: "valorCinco",
      render: (name) => {
        return (
          <Form.Item name={name}>
            <Radio.Group name={name} onChange={onChangeScore}>
              <Radio value={5}>5</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      name: "¿Causarle hinchazón en los tobillos o piernas?",
      valorCero: "causarleHinchazonEnLosTobillosOPiernas",
      valorUno: "causarleHinchazonEnLosTobillosOPiernas",
      valorDos: "causarleHinchazonEnLosTobillosOPiernas",
      valorTres: "causarleHinchazonEnLosTobillosOPiernas",
      valorCuatro: "causarleHinchazonEnLosTobillosOPiernas",
      valorCinco: "causarleHinchazonEnLosTobillosOPiernas",
    },
    {
      key: "2",
      name: "¿Hacer que tenga que sentarse o recostarse para descansar durante el día?",
      valorCero: "haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia",
      valorUno: "haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia",
      valorDos: "haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia",
      valorTres: "haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia",
      valorCuatro:
        "haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia",
      valorCinco:
        "haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia",
    },
    {
      key: "3",
      name: "¿Causarle dificultad para caminar o subir escaleras?",
      valorCero: "causarleDificultadParaCaminarOSubirEscaleras",
      valorUno: "causarleDificultadParaCaminarOSubirEscaleras",
      valorDos: "causarleDificultadParaCaminarOSubirEscaleras",
      valorTres: "causarleDificultadParaCaminarOSubirEscaleras",
      valorCuatro: "causarleDificultadParaCaminarOSubirEscaleras",
      valorCinco: "causarleDificultadParaCaminarOSubirEscaleras",
    },
    {
      key: "4",
      name: "¿Causarle dificultad para realizar trabajos en la casa o el jardín?",
      valorCero: "causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin",
      valorUno: "causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin",
      valorDos: "causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin",
      valorTres: "causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin",
      valorCuatro: "causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin",
      valorCinco: "causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin",
    },
    {
      key: "5",
      name: "¿Causarle dificultad para ir a lugares alejados de su casa?",
      valorCero: "causarleDificultadParaIrALugaresAlejadosDeSuCasa",
      valorUno: "causarleDificultadParaIrALugaresAlejadosDeSuCasa",
      valorDos: "causarleDificultadParaIrALugaresAlejadosDeSuCasa",
      valorTres: "causarleDificultadParaIrALugaresAlejadosDeSuCasa",
      valorCuatro: "causarleDificultadParaIrALugaresAlejadosDeSuCasa",
      valorCinco: "causarleDificultadParaIrALugaresAlejadosDeSuCasa",
    },
    {
      key: "6",
      name: "¿Causarle dificultad para dormir bien de noche?",
      valorCero: "causarleDificultadParaDormirBienDeNoche",
      valorUno: "causarleDificultadParaDormirBienDeNoche",
      valorDos: "causarleDificultadParaDormirBienDeNoche",
      valorTres: "causarleDificultadParaDormirBienDeNoche",
      valorCuatro: "causarleDificultadParaDormirBienDeNoche",
      valorCinco: "causarleDificultadParaDormirBienDeNoche",
    },
    {
      key: "7",
      name: "¿Causarle dificultad para relacionarse o hacer cosas con su familia o amigos?",
      valorCero:
        "causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos",
      valorUno:
        "causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos",
      valorDos:
        "causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos",
      valorTres:
        "causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos",
      valorCuatro:
        "causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos",
      valorCinco:
        "causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos",
    },
    {
      key: "8",
      name: "¿Causarle dificultad para trabajar y ganar un sueldo?",
      valorCero: "causarleDificultadParaTrabajarYGanarUnSueldo",
      valorUno: "causarleDificultadParaTrabajarYGanarUnSueldo",
      valorDos: "causarleDificultadParaTrabajarYGanarUnSueldo",
      valorTres: "causarleDificultadParaTrabajarYGanarUnSueldo",
      valorCuatro: "causarleDificultadParaTrabajarYGanarUnSueldo",
      valorCinco: "causarleDificultadParaTrabajarYGanarUnSueldo",
    },
    {
      key: "9",
      name: "¿Causarle dificultad con sus pasatiempos, deportes o hobbies?",
      valorCero: "causarleDificultadConSusPasatiemposDeportesOHobbies",
      valorUno: "causarleDificultadConSusPasatiemposDeportesOHobbies",
      valorDos: "causarleDificultadConSusPasatiemposDeportesOHobbies",
      valorTres: "causarleDificultadConSusPasatiemposDeportesOHobbies",
      valorCuatro: "causarleDificultadConSusPasatiemposDeportesOHobbies",
      valorCinco: "causarleDificultadConSusPasatiemposDeportesOHobbies",
    },
    {
      key: "10",
      name: "¿Causarle dificultad en las relaciones sexuales?",
      valorCero: "causarleDificultadELasRelacionesSexuales",
      valorUno: "causarleDificultadELasRelacionesSexuales",
      valorDos: "causarleDificultadELasRelacionesSexuales",
      valorTres: "causarleDificultadELasRelacionesSexuales",
      valorCuatro: "causarleDificultadELasRelacionesSexuales",
      valorCinco: "causarleDificultadELasRelacionesSexuales",
    },
    {
      key: "11",
      name: "¿Hacer que tenga que comer menos de las comidas que a usted le gustan?",
      valorCero: "hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan",
      valorUno: "hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan",
      valorDos: "hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan",
      valorTres: "hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan",
      valorCuatro: "hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan",
      valorCinco: "hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan",
    },
    {
      key: "12",
      name: "¿Causarle falta de aire?",
      valorCero: "causarleFaltaDeAire",
      valorUno: "causarleFaltaDeAire",
      valorDos: "causarleFaltaDeAire",
      valorTres: "causarleFaltaDeAire",
      valorCuatro: "causarleFaltaDeAire",
      valorCinco: "causarleFaltaDeAire",
    },
    {
      key: "13",
      name: "¿Hacer que se sienta cansado, fatigado o con poca energía?",
      valorCero: "hacerQueSeSientaCansadoFatigadoOConPocaEnergia",
      valorUno: "hacerQueSeSientaCansadoFatigadoOConPocaEnergia",
      valorDos: "hacerQueSeSientaCansadoFatigadoOConPocaEnergia",
      valorTres: "hacerQueSeSientaCansadoFatigadoOConPocaEnergia",
      valorCuatro: "hacerQueSeSientaCansadoFatigadoOConPocaEnergia",
      valorCinco: "hacerQueSeSientaCansadoFatigadoOConPocaEnergia",
    },
    {
      key: "14",
      name: "¿Hacer que tenga que estar internado en un hospital o sanatorio?",
      valorCero: "hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio",
      valorUno: "hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio",
      valorDos: "hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio",
      valorTres: "hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio",
      valorCuatro: "hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio",
      valorCinco: "hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio",
    },
    {
      key: "15",
      name: "¿Producirle gastos en salud?",
      valorCero: "producirleGastosEnLaSalud",
      valorUno: "producirleGastosEnLaSalud",
      valorDos: "producirleGastosEnLaSalud",
      valorTres: "producirleGastosEnLaSalud",
      valorCuatro: "producirleGastosEnLaSalud",
      valorCinco: "producirleGastosEnLaSalud",
    },
    {
      key: "16",
      name: "¿Causarle efectos colaterales / indeseables a causa de la medicación?",
      valorCero: "causarleEfectosColateralesIndeseablesACausaDeLaMedicacion",
      valorUno: "causarleEfectosColateralesIndeseablesACausaDeLaMedicacion",
      valorDos: "causarleEfectosColateralesIndeseablesACausaDeLaMedicacion",
      valorTres: "causarleEfectosColateralesIndeseablesACausaDeLaMedicacion",
      valorCuatro: "causarleEfectosColateralesIndeseablesACausaDeLaMedicacion",
      valorCinco: "causarleEfectosColateralesIndeseablesACausaDeLaMedicacion",
    },
    {
      key: "17",
      name: "¿Hacerle que se sienta una carga para su familia o amigos?",
      valorCero: "hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos",
      valorUno: "hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos",
      valorDos: "hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos",
      valorTres: "hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos",
      valorCuatro: "hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos",
      valorCinco: "hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos",
    },
    {
      key: "18",
      name: "¿Hacerle sentir que tiene un menor control sobre su vida?",
      valorCero: "hacerleSentirQueTieneUnMenosControlSobreSuVida",
      valorUno: "hacerleSentirQueTieneUnMenosControlSobreSuVida",
      valorDos: "hacerleSentirQueTieneUnMenosControlSobreSuVida",
      valorTres: "hacerleSentirQueTieneUnMenosControlSobreSuVida",
      valorCuatro: "hacerleSentirQueTieneUnMenosControlSobreSuVida",
      valorCinco: "hacerleSentirQueTieneUnMenosControlSobreSuVida",
    },
    {
      key: "19",
      name: "¿Causarle preocupación?",
      valorCero: "causarlePreocupacion",
      valorUno: "causarlePreocupacion",
      valorDos: "causarlePreocupacion",
      valorTres: "causarlePreocupacion",
      valorCuatro: "causarlePreocupacion",
      valorCinco: "causarlePreocupacion",
    },
    {
      key: "20",
      name: "¿Causarle dificultad para concentrarse o recordar cosas?",
      valorCero: "causarleDificultadoParaConcentrarseORecordarCosas",
      valorUno: "causarleDificultadoParaConcentrarseORecordarCosas",
      valorDos: "causarleDificultadoParaConcentrarseORecordarCosas",
      valorTres: "causarleDificultadoParaConcentrarseORecordarCosas",
      valorCuatro: "causarleDificultadoParaConcentrarseORecordarCosas",
      valorCinco: "causarleDificultadoParaConcentrarseORecordarCosas",
    },
    {
      key: "21",
      name: "¿Hacer que se sienta deprimido?",
      valorCero: "hacerQueSeSientaDeprimido",
      valorUno: "hacerQueSeSientaDeprimido",
      valorDos: "hacerQueSeSientaDeprimido",
      valorTres: "hacerQueSeSientaDeprimido",
      valorCuatro: "hacerQueSeSientaDeprimido",
      valorCinco: "hacerQueSeSientaDeprimido",
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={data} />
      <Form.Item label="Sumatoria resultado físico">
        <InputNumber
          disabled={true}
          name="sumatoriaResultadoFisico"
          onChange={(value) =>
            onChangeNumber(value, "sumatoriaResultadoFisico")
          }
          value={state.sumatoriaResultadoFisico}
        />
      </Form.Item>
      <Form.Item label="Sumatoria resultado emocional">
        <InputNumber
          disabled={true}
          name="sumatoriaResultadoEmocional"
          onChange={(value) =>
            onChangeNumber(value, "sumatoriaResultadoEmocional")
          }
          value={state.sumatoriaResultadoEmocional}
        />
      </Form.Item>
      <Form.Item label="Dimensión socioeconomica">
        <InputNumber
          disabled={true}
          name="sumatoriaDimensionSocioeconomica"
          onChange={(value) =>
            onChangeNumber(value, "sumatoriaDimensionSocioeconomica")
          }
          value={state.sumatoriaDimensionSocioeconomica}
        />
      </Form.Item>

      <Form.Item label="Total">
        <InputNumber
          disabled={true}
          name="totalCuestionario"
          onChange={(value) => onChangeNumber(value, "totalCuestionario")}
          value={state.totalCuestionario}
        />
      </Form.Item>
    </>
  );
}

export default Cuestionario;
