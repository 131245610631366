import React from "react";
import { Form, Radio, Input } from "antd";

const Antecedentes = ({
  onChangeField,
  onChangeDate,
  onChangeCheck,
  state,
}) => {
  return (
    <div>
      <Form.Item label="Cuidados paliativos" name="cuidadosPaliativos">
        <Radio.Group onChange={onChangeField} name="cuidadosPaliativos">
          <Radio value="Si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Rehabilitación" name="rehabilitacion">
        <Radio.Group onChange={onChangeField} name="rehabilitacion">
          <Radio value="Si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Sesiones al mes" name="sesionesAlMes">
        <Input onChange={onChangeField} name="sesionesAlMes" />
      </Form.Item>
      <Form.Item label="Soporte de apoyo" name="soporteDeApoyo">
        <Radio.Group onChange={onChangeField} name="soporteDeApoyo">
          <Radio value="perteneceAUnGrupoDeApoyo">
            Pertenece a un grupo de apoyo
          </Radio>
          <Radio value="noPerteneceANingunGrupoDeApoyo">
            No pertenece a ningún grupo de apoyo
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="¿Se ha hospitalizado desde la última vez que vino a consulta?"
        name="seHaHospitalizadoDesdeLaUltimaVezQueVinoAConsulta"
      >
        <Radio.Group
          name="seHaHospitalizadoDesdeLaUltimaVezQueVinoAConsulta"
          onChange={onChangeField}
        >
          <Radio value="si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="¿Ha recibido atención de urgencias (no hospitalización) desde la última vez que vino a consulta?"
        name="haRecibidoAtencionDeUrgenciasDesdeLaUltimaVezQueVinoAConsulta"
      >
        <Radio.Group
          name="haRecibidoAtencionDeUrgenciasDesdeLaUltimaVezQueVinoAConsulta"
          onChange={onChangeField}
        >
          <Radio value="si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="¿Ha tenido días de incapacidad desde la última vez que vino a consulta?"
        name="haTenidooDiasDeIncapacidadDesdeLaUltimaVezQueVinoAConsulta"
      >
        <Radio.Group
          name="haTenidooDiasDeIncapacidadDesdeLaUltimaVezQueVinoAConsulta"
          onChange={onChangeField}
        >
          <Radio value="si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Número de días" name="numeroDeDias">
        <Input onChange={onChangeField} name="numeroDeDias" />
      </Form.Item>
    </div>
  );
};

export default Antecedentes;
