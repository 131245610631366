import { cmToMeters } from "./conversions";
import {
  floatRegularExpression3Max,
  changeForm,
  changeNewStateObject,
} from "./rules";

function calculateIMC(context, event) {
  const { peso, talla } = context.state;
  const IMC =
    (peso / Math.pow(talla * 1 * cmToMeters, 2)).toFixed(2) === "Infinity"
      ? ""
      : (peso / Math.pow(talla * 1 * cmToMeters, 2)).toFixed(2);

  changeForm(context, { IMC });
}

export async function onChangeNum(context, event) {
  const { name } = event.target;
  let value = event.target.value;
  let newState = {};
  const matched = value.match(floatRegularExpression3Max);
  value = "";
  if (matched !== null)
    value = `${matched[0]}${matched[1] ? matched[1].replace(".", "") : ""}`;

  newState = changeNewStateObject(newState, { [name]: value });

  await changeForm(context, newState);

  if (event.target.name === "talla" || event.target.name === "peso")
    calculateIMC(context, event);
}
