import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const ARAII = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Candesartán"
                checked={state.candersartanAnteriorCheck}
                input={state.candersartanAnterior}
                date={state.candersartanAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="candersartanAnterior"
                nameCheck="candersartanAnteriorCheck"
                nameDate="candersartanAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Valsartán"
                checked={state.valsartanAnteriorCheck}
                input={state.valsartanAnterior}
                date={state.valsartanAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="valsartanAnterior"
                nameCheck="valsartanAnteriorCheck"
                nameDate="valsartanAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Losartán"
                checked={state.losartanAnteriorCheck}
                input={state.losartanAnterior}
                date={state.losartanAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="losartanAnterior"
                nameCheck="losartanAnteriorCheck"
                nameDate="losartanAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Telmisartán"
                checked={state.telmisartanAnteriorCheck}
                input={state.telmisartanAnterior}
                date={state.telmisartanAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="telmisartanAnterior"
                nameCheck="telmisartanAnteriorCheck"
                nameDate="telmisartanAnteriorDate"
            />
        </>
    );
};

export default ARAII;