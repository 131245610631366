
import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";
import MedicamentoOtroDisabled from "../../components/forms/MedicamentoOtroDisabled";

const OtrosMedicamentoDisableds = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Vericiguat"
                checked={state.vericiguatAnteriorCheck}
                input={state.vericiguatAnterior}
                date={state.vericiguatAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="vericiguatAnterior"
                nameCheck="vericiguatAnteriorCheck"
                nameDate="vericiguatAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Omencativ mercarbil"
                checked={state.omencativMercarbilAnteriorCheck}
                input={state.omencativMercarbilAnterior}
                date={state.omencativMercarbilAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="omencativMercarbilAnterior"
                nameCheck="omencativMercarbilAnteriorCheck"
                nameDate="omencativMercarbilAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Dapagliflozina"
                checked={state.dapagliflozinaAnteriorCheck}
                input={state.dapagliflozinaAnterior}
                date={state.dapagliflozinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dapagliflozinaAnterior"
                nameCheck="dapagliflozinaAnteriorCheck"
                nameDate="dapagliflozinaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Empagliflozina"
                checked={state.empagliflozinaAnteriorCheck}
                input={state.empagliflozinaAnterior}
                date={state.empagliflozinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="empagliflozinaAnterior"
                nameCheck="empagliflozinaAnteriorCheck"
                nameDate="empagliflozinaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Estatinas"
                checked={state.estatinasAnteriorCheck}
                input={state.estatinasAnterior}
                date={state.estatinasAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="estatinasAnterior"
                nameCheck="estatinasAnteriorCheck"
                nameDate="estatinasAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Anticoagulantes
                orales"
                checked={state.anticoagulantesOralesAnteriorCheck}
                input={state.anticoagulantesOralesAnterior}
                date={state.anticoagulantesOralesAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="anticoagulantesOralesAnterior"
                nameCheck="anticoagulantesOralesAnteriorCheck"
                nameDate="anticoagulantesOralesAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Antiagregantes
                plaquetarios"
                checked={state.antiagregantesPlaquetariosAnteriorCheck}
                input={state.antiagregantesPlaquetariosAnterior}
                date={state.antiagregantesPlaquetariosAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="antiagregantesPlaquetariosAnterior"
                nameCheck="antiagregantesPlaquetariosAnteriorCheck"
                nameDate="antiagregantesPlaquetariosAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="AINES"
                checked={state.AINESAnteriorCheck}
                input={state.AINESAnterior}
                date={state.AINESAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="AINESAnterior"
                nameCheck="AINESAnteriorCheck"
                nameDate="AINESAnteriorDate"
            />
            <MedicamentoOtroDisabled
                labelName="Otro"
                checked={state.primerOtroAnteriorCheck}
                input={state.primerOtroAnterior}
                date={state.primerOtroAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="primerOtroAnterior"
                nameCheck="primerOtroAnteriorCheck"
                nameDate="primerOtroAnteriorDate"
                nameOtro="primerOtroAnteriorNombre"
            />
            <MedicamentoOtroDisabled
                labelName="Otro"
                checked={state.segundoOtroAnteriorCheck}
                input={state.segundoAnteriorOtro}
                date={state.segundoOtroAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="segundoOtroAnterior"
                nameCheck="segundoOtroAnteriorCheck"
                nameDate="segundoOtroAnteriorDate"
                nameOtro="segundoOtroAnteriorNombre"
            />
            <MedicamentoOtroDisabled
                labelName="Otro"
                checked={state.tercerOtroAnteriorCheck}
                input={state.tercerOtroAnterior}
                date={state.tercerOtroAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="tercerOtroAnterior"
                nameCheck="tercerOtroAnteriorCheck"
                nameDate="tercerOtroAnteriorDate"
                nameOtro="tercerOtroAnteriorNombre"
            />
            <MedicamentoOtroDisabled
                labelName="Otro"
                checked={state.cuartoOtroAnteriorCheck}
                input={state.cuartoOtroAnterior}
                date={state.cuartoOtroAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="cuartoOtroAnterior"
                nameCheck="cuartoOtroAnteriorCheck"
                nameDate="cuartoOtroAnteriorDate"
                nameOtro="cuartoOtroAnteriorNombre"
            />
        </>
    );
};

export default OtrosMedicamentoDisableds;