import { PacienteActionTypes } from "./paciente.types";

const INITIAL_STATE = [1];

const pacienteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PacienteActionTypes.SET_CURRENT_PACIENTES:
      return action.payload;
    default:
      return state;
  }
};

export default pacienteReducer;
