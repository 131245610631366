import React from "react";

import { Form, Input, Select, Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import "./UsuariosNew.css";

const UsuariosNew = ({
  addNewUser,
  handleChange,
  hideUserForm,
  darkBgStyles,
  displayStyle,
  sideContainerStyles,
  role,
}) => {
  return (
    <div className="UsuariosNew">
      <div className="dark-bg" style={{ ...darkBgStyles, ...displayStyle }}>
        <div
          className="side-container"
          style={{ ...sideContainerStyles, ...displayStyle }}
        >
          <Form>
            <Button className="close" onClick={hideUserForm}>
              x
            </Button>
            <h2>Nuevo Usuario</h2>
            <div className="form-group full-width">
              <Input name="name" placeholder="Nombre" onChange={handleChange} />
            </div>
            <div className="form-group">
              <Input name="email" placeholder="Email" onChange={handleChange} />
              <select value={role} name="role" onChange={handleChange}>
                <option value="-" disabled>
                  Selecciona el rol
                </option>
                <option value="enfermero">Enfermero/a</option>
                <option value="doctor">Doctor/a</option>
              </select>
            </div>
            <div className="form-group">
              <Input
                name="password"
                type="password"
                placeholder="Contraseña"
                onChange={handleChange}
              />
              <Input
                name="confirmpassword"
                type="password"
                placeholder="Confirma contraseña"
                onChange={handleChange}
              />
            </div>
            <div className="form-group full-width">
              <Button type="submit" onClick={addNewUser}>
                Crear Usuario
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UsuariosNew;
