import React from "react";
import { Form, Input } from "antd";

import { ruleNumber } from "../../utils/rules";

const EstudiosDeLaboratorios = ({ onChangeField, onChangeNumber, state }) => {
  return (
    <>
      <div className="checkBoxContainer">
        <div>
          <Form.Item
            label="Hemoglobina"
            name="hemoglobina"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="hemoglobina"
            />
          </Form.Item>
          <Form.Item
            label="Leucocitos"
            name="leucocitos"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="leucocitos"
            />
          </Form.Item>
          <Form.Item
            label="Sodio"
            name="sodio"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="sodio"
            />
          </Form.Item>
          <Form.Item
            label="Potasio"
            name="potasio"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="potasio"
            />
          </Form.Item>
          <Form.Item
            label="Urea"
            name="urea"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="urea"
            />
          </Form.Item>
          <Form.Item
            label="Creatinina"
            name="creatinina"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="creatinina"
            />
          </Form.Item>
          <Form.Item
            label="TFGe"
            name="TFGe"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="TFGe"
            />
          </Form.Item>
          {/** 
          <Form.Item
            label="Función hepática (bilirrubina, AST, ALT,GGTP  )"
            name="funcionHepatica"
            labelCol={{ span: 15 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="funcionHepatica"
            />
          </Form.Item>
          */}
          <Form.Item
            label="Bilirrubina"
            name="bilirrubina"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="bilirrubina"
            />
          </Form.Item>
          <Form.Item
            label="AST"
            name="AST"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="AST"
            />
          </Form.Item>
          <Form.Item
            label="ALT"
            name="ALT"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="ALT"
            />
          </Form.Item>
          <Form.Item
            label="GGTP"
            name="GGTP"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="GGTP"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            label="Glucosa"
            name="glucosa"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="glucosa"
            />
          </Form.Item>
          <Form.Item
            label="HbA1c"
            name="HbA1c"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="HbA1c"
            />
          </Form.Item>
          <Form.Item
            label="Perfil lipídico"
            name="perfilLipidico"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="perfilLipidico"
            />
          </Form.Item>
          <Form.Item
            label="TSH"
            name="TSH"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="TSH"
            />
          </Form.Item>
          {/** 
          <Form.Item
            label="Ferritina, TSAT = TIBC"
            name="ferritinaTSATTIBC"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="ferritinaTSATTIBC"
            />
          </Form.Item>
          */}
          <Form.Item
            label="Ferritina"
            name="ferritina"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="ferritina"
            />
          </Form.Item>
          <Form.Item
            label="Saturación de transferrina"
            name="saturacionDeTransferrina"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="saturacionDeTransferrina"
            />
          </Form.Item>
          <Form.Item
            label="BNP"
            name="BNP"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="BNP"
            />
          </Form.Item>
          <Form.Item
            label="NT-proBNP"
            name="NTproBNP"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="NTproBNP"
            />
          </Form.Item>
          <Form.Item
            label="Cloro"
            name="cloro"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="cloro"
            />
          </Form.Item>
          <Form.Item
            label="Cockcroft - gault"
            name="cockcroftGault"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="cockcroftGault"
            />
          </Form.Item>
          <Form.Item
            label="MDRD"
            name="MDRD"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="MDRD"
            />
          </Form.Item>
          <Form.Item
            label="CKD-EPI"
            name="CKDEPI"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            rules={ruleNumber}
            validateTrigger="onChange"
          >
            <Input
              style={{ width: "40%" }}
              onChange={(e) => onChangeNumber(e)}
              name="CKDEPI"
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default EstudiosDeLaboratorios;
