import React, { Component } from "react";
import moment from "moment";
import { Form, Collapse, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./Hospitalizacion.css";

import Header from "../../shared/components/layout/Header";
import GeneralDataHospitalizacion from "../components/GeneralDataHospitalizacion";
import HospitalizacionComponent from "../components/HospitalizacionComponent";
import SignosVitalesAlIngreso from "../components/SignosVitalesAlIngreso";
import EstudiosDeGabinete from "../../shared/EstudiosDeGabinete/EstudiosDeGabinete";
import EstudiosDeLaboratorio from "../../shared/EstudiosDeLaboratorio/EstudiosDeLaboratorios";
import Medicamentos from "../../shared/medicamento/pages/Medicamentos";
import IntervencionesDuranteLaHospitalizacion from "../components/IntervencionesDuranteLaHospitalizacion";
import withParams from "../../hooks/withParams";
import { onChangeNum } from "../../utils/onChangeNum";

const { Panel } = Collapse;

class Hospitalizacion extends Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.form = "hospitalization";
    this.state = {
      id_paciente: "",
      //Datos Generales
      numeroDeHospitalizacion: 2,
      cuatroUltimosDigitosDeLaCedula: "",
      fechaDeNacimiento: "",
      //HospitalizacionComponente
      fechaDeIngresoHospitalizacion: "",
      fechaDeEgresoHospitalizacion: "",
      nombreDelHospitalDondeSeHospitalizo: "",
      relacionadoConIC: "",
      diasEnPiso: 0,
      diasEnTerapiaIntensiva: 0,
      diasTotales: 0,
      asistenciaVentilatoria: "",
      desenlaceHospitalario: "",
      //FD
      taquiarritmia: false,
      aumentoExcesivoDeLaPresionArterial: false,
      infeccion: false,
      faltaDeAdherenciaALaRestriccionDeSalOMedicacion: false,
      bradiattirmia: false,
      //sustanciasToxicas: false,
      farmacos: false,
      exacerbacionDeLaEnfermedadPulmonarObstructivaCronica: false,
      emboliaPulmonar: false,
      cirugiaYComplicacionesPerioperatorias: false,
      aumentoDelImpulsoSimpaticoMiocardioRelacionadaConEstres: false,
      alteracionesHormonalesMetabolicas: false,
      dañoCerebrovascular: false,
      causaMecanicaAguda: false,
      // Signos vitales
      frecuenciaCardiaca: 0,
      TASistolica: 0,
      TADiastolica: 0,
      peso: 0,
      saturacionDeODos: 0,
      //Estudios de gabinete
      radiografiaDeToraxCheck: false,
      radioTorax: "",
      ECOTranstoracicoCheck: false,
      FEVI: 0,
      RelacionEA: 0,
      disfuncionDiastolicaSistolicaCheck: false,
      disfuncionDiastolica: 0,
      enfermedadValvularCheck: false,
      tipoEnfermedadValvular: "",
      funcionDeVD: "",
      presionSistolica: "",
      volAuricular: "",
      ecocardiografiaTransesofagicaCheck: false,
      ecocardiografiaDeEstresCheck: false,
      resonanciaMagneticaCheck: false,
      angiografiaCoronariaCheck: false,
      tomografiaComputarizadaCardiacaCheck: false,
      holterCheck: false,
      pruebaDeEsfuerzoCheck: false,
      electrocardiogramaQRSCheck: false,
      QRSElectrocardiograma: 0,
      radioFa: false,
      // Estudios de laboratorio
      hemoglobina: 0,
      leucocitos: "",
      sodio: "",
      potasio: "",
      urea: "",
      creatinina: "",
      TFGe: "",
      funcionHepatica: "",
      glucosa: "",
      HbA1c: "",
      perfilLipidico: "",
      TSH: "",
      ferritinaTSATTIBC: "",
      BNP: "",
      NTproBNP: "",
      cloro: "",
      //Medicamentos
      captoprilCheck: false,
      captopril: "",
      captoprilDate: "",

      enalaprilCheck: false,
      enalapril: "",
      enalaprilDate: "",

      lisinoprilCheck: false,
      lisinopril: false,
      lisinoprilDate: "",

      ramiprilCheck: false,
      ramipril: false,
      ramiprilDate: "",

      trandolaprilaCheck: false,
      trandolaprila: false,
      trandolaprilaDate: "",

      bisoprololCheck: false,
      bisoprolol: false,
      bisoprololDate: "",

      carveilolCheck: false,
      carvedilol: false,
      carvedilolDate: "",

      succinatoDeMetoprololCheck: false,
      succinatoDeMetoprolol: false,
      succinatoDeMetoprololDate: "",

      nebivololCheck: false,
      nebivolol: false,
      nebivololDate: "",

      tartratoDeMetoprololCheck: false,
      tartratoDeMetoprolol: false,
      tartratoDeMetoprololDate: "",

      candersartanCheck: false,
      candersartan: false,
      candersartanDate: "",

      valsartanCheck: false,
      valsartan: false,
      valsartanDate: "",

      losartanCheck: false,
      losartan: false,
      losartanDate: "",

      telmisartanCheck: false,
      telmisartan: false,
      telmisartanDate: "",

      eplerenonaCheck: false,
      eplerenona: false,
      eplerenonaDate: "",

      espironolactonaCheck: false,
      espironolactona: false,
      espironolactonaDate: "",

      sacubitriloValsartanCheck: false,
      sacubitriloValsartan: false,
      sacubitriloValsartanDate: "",

      ivabradinaCheck: false,
      ivabradina: false,
      ivabradinaDate: "",

      furosemidaOralCheck: false,
      furosemidaOral: false,
      furosemidaOralDate: "",

      furosemidaIVCheck: false,
      furosemidaIV: false,
      furosemidaIVDate: "",

      bumetanidaCheck: false,
      bumetanida: false,
      bumetanidaDate: "",

      hidroclorotiazidaCheck: false,
      hidroclorotiazida: false,
      hidroclorotiazidaDate: "",

      clortalidonaCheck: false,
      clortalidona: false,
      clortalidonaDate: "",

      indapamidaCheck: false,
      indapamida: false,
      indapamidaDate: "",

      espironolactonaEplerrenonaCheck: false,
      espironolactonaEplerrenona: false,
      espironolactonaEplerrenonaDate: "",

      amiloridaCheck: false,
      amilorida: false,
      amiloridaDate: "",

      triamterenoCheck: false,
      triamtereno: false,
      triamterenoDate: "",

      digoxinaCheck: false,
      digoxina: false,
      digoxinaDate: "",

      hidralazinaCheck: false,
      hidralazina: false,
      hidralazinaDate: "",

      nitroglicerinaCheck: false,
      nitroglicerina: false,
      nitroglicerinaDate: "",

      dinitratoDeIsosorbidaCheck: false,
      dinitratoDeIsosorbida: false,
      dinitratoDeIsosorbidaDate: "",

      nitroprusiatoCheck: false,
      nitroprusiato: false,
      nitroprusiatoDate: "",

      nesiritidaCheck: false,
      nesiritida: false,
      nesiritidaDate: "",

      noradrenalinaCheck: false,
      noradrenalina: false,
      noradrenalinaDate: "",

      epinefrinaCheck: false,
      epinefrina: false,
      epinefrinaDate: "",

      dobutaminaCheck: false,
      dobutamina: false,
      dobutaminaDate: "",

      dopaminaCheck: false,
      dopamina: false,
      dopaminaDate: "",

      levosimendanCheck: false,
      levosimendan: false,
      levosimendanDate: "",

      inhibidoresDeLaFosfodiesterasaIIICheck: false,
      inhibidoresDeLaFosfodiesterasaIII: false,
      inhibidoresDeLaFosfodiesterasaIIIDate: "",

      vericiguatCheck: false,
      vericiguat: false,
      vericiguatDate: "",

      omencativMercarbilCheck: false,
      omencativMercarbil: false,
      omencativMercarbilDate: "",

      dapagliflozinaCheck: false,
      dapagliflozina: false,
      dapagliflozinaDate: "",

      empagliflozinaCheck: false,
      empagliflozina: false,
      empagliflozinaDate: "",

      estatinasCheck: false,
      estatinas: false,
      estatinasDate: "",

      anticoagulantesOralesCheck: false,
      anticoagulantesOrales: false,
      anticoagulantesOralesDate: "",

      antiagregantesPlaquetariosCheck: false,
      antiagregantesPlaquetarios: false,
      antiagregantesPlaquetariosDate: "",

      AINESCheck: false,
      AINES: false,
      AINESDate: "",

      primerOtroCheck: false,
      primerOtro: "22",
      primerOtroDate: "",
      primerOtroNombre: "",

      segundoOtroCheck: false,
      segundoOtro: "22",
      segundoOtroDate: "",
      segundoOtroNombre: "",

      tercerOtroCheck: false,
      tercerOtro: "22",
      tercerOtroDate: "",
      tercerOtroNombre: "",

      cuartoOtroCheck: false,
      cuartoOtro: "22",
      cuartoOtroDate: "",
      cuartoOtroNombre: "",
      //Intervenciones durante la hosputalizacion
      fechaDeImplanteHospitalizacion: "",
      fechaDeImplanteHospitalizacionRadio: "CRTD",
      SiElPacienteTieneIndicacionADispositivoPeroNoSeImplanto:
        "otnegativaDelPacientero1",
      SiElPacienteTieneIndicacionADispositivoPeroNoSeImplantoOtro: "",
      asistenciaMecanicaCirculatoria: "DAVI",
      trasplanteCardiaco: "Si",
    };
  }

  componentDidMount = async () => {
    const { id_paciente, id } = this.props.params;
    const hospi = await fetch(
      `https://ximet.herokuapp.com/api/v1/hospitalization/${id_paciente}/${id}`
    )
      .then((res) => res.json())
      .then((response) => response.data);

    Object.keys(hospi).forEach((key) => {
      if (
        (key.includes("Date") ||
          key.includes("date") ||
          key.includes("fecha") ||
          key.includes("nacimiento")) &&
        hospi[key] !== null &&
        !key.includes("fechaDeImplanteHospitalizacionRadio")
      ) {
        hospi[key] = moment(hospi[key]);
      }
    });

    this.ref.current.setFieldsValue({
      ...hospi,
    });

    this.setState({ ...hospi });
  };

  onChangeField = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onChangeNumber = (e) => {
    onChangeNum(this, e);
  };

  onChangeDate = (date, dateString, name) => {
    this.setState({
      [name]: dateString,
    });
  };

  onFinish = async (values) => {
    const { id_paciente, id } = this.props.params;
    const response = await fetch(
      `https://ximet.herokuapp.com/api/v1/hospitalization/${id_paciente}/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(this.state),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        res.json();
      })
      .then((json) => json)
      .catch((err) => console.log(err));

    console.log(this.state);

    Modal.success({
      title: "Has guardado correctamente!",
    });
  };

  showWarningModal = () => {
    const clearAll = () => {
      let newState = {};
      Object.keys(this.state).forEach((key) => {
        newState[key] = null;
        if (
          key === "id_paciente" ||
          key === "id" ||
          key === "nacimiento" ||
          key === "cuatroUltimosDigitosDeLaCedula" ||
          key === "numeroDeHospitalizacion"
        ) {
          newState[key] = this.state[key];
        }
      });
      this.setState(newState, () => console.log(this.state));
      this.ref.current.resetFields();
      this.ref.current.setFieldsValue({
        id_paciente: this.props.params.id_paciente,
        id: this.state.id,
        nacimiento: this.state.nacimiento,
        cuatroUltimosDigitosDeLaCedula:
          this.state.cuatroUltimosDigitosDeLaCedula,
        numeroDeHospitalizacion: this.state.numeroDeHospitalizacion,
      });
    };

    Modal.confirm({
      title: "Espera!",
      icon: <ExclamationCircleOutlined />,
      content: `Seguro que quieres borrar todos los campos?`,
      okText: "Borrar",
      cancelText: "Cancelar",
      onOk() {
        return clearAll();
      },
      onCancel() {
        return;
      },
    });
  };

  render() {
    const {
      onChangeField,
      onChangeCheck,
      onChangeNumber,
      onChangeDate,
      onFinish,
      state,
      showWarningModal,
    } = this;

    return (
      <div>
        <Header
          state={state}
          formType={this.form}
          id_paciente={this.props.params.id_paciente}
          formID={this.props.params.id}
          clearAll={showWarningModal}
        />
        <Form
          ref={this.ref}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          onFinish={onFinish}
        >
          <GeneralDataHospitalizacion
            onChangeField={onChangeField}
            onChangeDate={onChangeDate}
            onChangeCheck={onChangeCheck}
            onChangeNumber={onChangeNumber}
            state={state}
          />
          <Collapse bordered={false} defaultActiveKey={["1"]}>
            <Panel forceRender={true} header="Hospitalización" key="1">
              <HospitalizacionComponent
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel
              forceRender={true}
              header="Signos Vitales al ingreso"
              key="2"
            >
              <SignosVitalesAlIngreso
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel
              forceRender={true}
              header="Estudios de gabinete durante la hospitalización"
              key="3"
            >
              <EstudiosDeGabinete
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={true} header="Estudios de laboratorios" key="4">
              <EstudiosDeLaboratorio
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={true} header="Medicamentos actuales" key="5">
              <Medicamentos
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel
              forceRender={true}
              header="Intervenciones durante la hospitalización"
              key="6"
            >
              <IntervencionesDuranteLaHospitalizacion
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
          </Collapse>
          <button id={this.form} type="submit" hidden={true}>
            SUBMIT
          </button>
        </Form>
      </div>
    );
  }
}

export default withParams(Hospitalizacion);
