import React from "react";
import { Form, Input, DatePicker, Divider, Radio } from "antd";

const GeneralDataConsulta = ({ onChangeField, onChangeDate, state }) => {
  return (
    <div>
      <Form.Item label="ID" name="id_paciente">
        <Input onChange={onChangeField} name="id_paciente" disabled={true} />
      </Form.Item>
      <Form.Item label="Fecha de nacimiento" name="nacimiento">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "nacimiento")
          }
          name="nacimiento"
          disabled={true}
        />
      </Form.Item>
      <Divider />
      <h1>Consulta</h1>
      <Form.Item label="Fecha de consulta" name="fechaDeConsulta">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "fechaDeConsulta")
          }
        />
      </Form.Item>
      <Form.Item label="Clase funcional NYHA" name="claseFuncionalNYHA">
        <Radio.Group onChange={onChangeField} name="claseFuncionalNYHA">
          <Radio value="I">I</Radio>
          <Radio value="II">II</Radio>
          <Radio value="III">III</Radio>
          <Radio value="IV">IV</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default GeneralDataConsulta;
