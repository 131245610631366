import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { current } from "@reduxjs/toolkit";

const UsuariosDeleteModal = ({
  isDeleteModalVisible,
  currentUser,
  setIsDeleteModalVisible,
  getUsers,
}) => {
  const handleDeletePaciente = async () => {
    const req = await fetch(
      `
      https://ximet.herokuapp.com/api/v1/users/?email=${currentUser.email}`,
      {
        method: "DELETE",
      }
    );
    console.log(req);
    await getUsers();
    return setIsDeleteModalVisible(false);
  };
  return (
    <Modal
      centered={true}
      title={
        <div>
          <ExclamationCircleOutlined style={{ color: "red" }} /> "Espera!!!"
        </div>
      }
      onOk={async () => {
        await new Promise(() => {
          handleDeletePaciente();
        }).catch(() => console.log("Oops errors!"));
      }}
      onCancel={() => {
        setIsDeleteModalVisible(false);
      }}
      okText="Borrar"
      cancelText="Cancelar"
      okType="danger"
      visible={isDeleteModalVisible}
    >
      Seguro que quieres borrar el usuario: <b>{currentUser.name}</b>
    </Modal>
  );
};

export default UsuariosDeleteModal;
