import React, { useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import { Layout, Menu, Affix } from "antd";
import { useSelector } from "react-redux";
import {
  UserOutlined,
  PlusCircleOutlined,
  UsergroupAddOutlined,
  FolderOpenOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import "./Sidebar.css";
import SubMenu from "antd/lib/menu/SubMenu";

const { Sider } = Layout;

const Sidebar = (props) => {
  const params = useParams();
  const pacientes = useSelector((state) => state.pacientes);
  const location = useLocation().pathname;
  const [collapsed, setCollapse] = useState(true);

  const onCollapse = () => {
    setCollapse(!collapsed);
  };

  if (
    location !== "/home" &&
    location !== "/dashboard" &&
    location !== "/usuarios"
  ) {
    const paciente = pacientes.filter(
      (item) => item.PacienteID === params.id_paciente
    );
    /*
    const nose = _.sortBy(paciente[0].Urgencia.records, function (record) {
      return record.id;
    });
    */
    const urgenciasRecords = paciente[0].Urgencia.records.sort(
      (a, b) => b["id"] - a["id"]
    );
    const consultasRecords = paciente[0].Consulta.records.sort(
      (a, b) => b["id"] - a["id"]
    );
    const hospitalizacionesRecords = paciente[0].Hospitalizacion.records.sort(
      (a, b) => b["id"] - a["id"]
    );

    return (
      <Affix offsetTop={0}>
        <Sider
          collapsible
          width={200}
          className="scollable-container site-layout-background"
          collapsed={collapsed}
          onCollapse={onCollapse}
          style={{
            overflow: "auto",
            height: "100%",
            left: 0,
            display: "block",
            bottom: 0,
          }}
        >
          <Menu
            theme="dark"
            className="SideBar"
            mode="inline"
            style={{ height: "100vh - ", borderRight: 0 }}
            defaultActiveFirst={"sub1"}
            defaultSelectedKeys={[location]}
          >
            <Menu.Item key="/home" icon={<HomeOutlined />}>
              {" "}
              <Link to={`/home`} />
              Home
            </Menu.Item>
            <Menu.Item key="/basal" icon={<UserOutlined />}>
              {" "}
              <Link to={`/basal/${params.id_paciente}`} />
              Basal
            </Menu.Item>
            <SubMenu
              key="consultasSub"
              icon={<FolderOpenOutlined />}
              title="Consultas"
            >
              {consultasRecords.length > 0 ? (
                consultasRecords.map((consulta, idx) => {
                  return (
                    <Menu.Item key={"consulta" + consulta.id}>
                      <Link
                        to={`/consulta/${paciente[0].PacienteID}/${consulta.id}`}
                      />
                      Consulta - {consultasRecords.length - idx}
                    </Menu.Item>
                  );
                })
              ) : (
                <Menu.Item disabled>
                  <div>No hay consultas </div>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="hospitalizacionSub"
              icon={<UsergroupAddOutlined />}
              title="Hospitalizaciones"
            >
              {hospitalizacionesRecords.length > 0 ? (
                hospitalizacionesRecords.map((hospit, idx) => {
                  return (
                    <Menu.Item key={"hospitalizacion" + hospit.id}>
                      <Link
                        to={`/hospitalizacion/${paciente[0].PacienteID}/${hospit.id}`}
                      />{" "}
                      Hospitalizacion - {hospitalizacionesRecords.length - idx}
                    </Menu.Item>
                  );
                })
              ) : (
                <Menu.Item disabled>
                  <div>No hay Hospitalizaciones </div>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="urgenciasSub"
              icon={<PlusCircleOutlined />}
              title="Urgencias"
            >
              {urgenciasRecords.length > 0 ? (
                urgenciasRecords.map((urgencia, idx) => {
                  return (
                    <Menu.Item key={"urgencia" + urgencia.id}>
                      <Link
                        to={`/urgencia/${paciente[0].PacienteID}/${urgencia.id}`}
                      />{" "}
                      Urgencia - {urgenciasRecords.length - idx}
                    </Menu.Item>
                  );
                })
              ) : (
                <Menu.Item disabled>
                  <div>No hay Urgencias </div>
                </Menu.Item>
              )}
            </SubMenu>
            <Menu.Item key="/desenlace" icon={<ExclamationCircleOutlined />}>
              <Link to={`/desenlace/${params.id_paciente}`} /> Desenlace
            </Menu.Item>
          </Menu>
        </Sider>
      </Affix>
    );
  } else {
    return false;
  }
};

export default Sidebar;
