import React from "react";

import UsuariosTable from "../components/UsuariosTable";
import UsuariosHeader from "../components/UsuariosHeader";
import UsuariosNew from "../components/UsuariosNew";

class Usuarios extends React.Component {
  constructor() {
    super();
    this.state = {
      usersRecords: [],
      displayStyle: {
        display: "none",
      },
      darkBgStyles: {
        opacity: 0,
      },
      sideContainerStyles: {
        width: 0,
      },
      role: "enfermero",
    };
  }

  getUsers = async () => {
    this.setState({ usersRecords: [] });
    let i = 0;

    await fetch("https://ximet.herokuapp.com/api/v1/users", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((records) => {
        const users = records.data.map((record) => {
          i = i + 1;
          return {
            key: record.id,
            ID: record.id,
            Name: record.name,
            Email: record.email,
            Role: record.role,
            Fecha: new Date(record.createdAt).toLocaleDateString(),
            Delete: { email: record.email, name: record.name },
          };
        });
        this.setState(() => ({
          usersRecords: users,
          users: i,
        }));
      });
    await this.setState({
      users: i,
    });
  };

  componentDidMount = async () => {
    this.getUsers();
  };

  displayUserForm = async () => {
    this.setState(() => ({
      displayStyle: {
        display: "block",
      },
    }));
    setTimeout(
      () =>
        this.setState({
          darkBgStyles: {
            opacity: 1,
          },
        }),
      10
    );
    setTimeout(
      () =>
        this.setState({
          sideContainerStyles: {
            width: "636px",
          },
        }),
      10
    );
  };

  hideUserForm = async () => {
    this.setState(() => ({
      darkBgStyles: {
        opacity: 0,
      },
      sideContainerStyles: {
        width: 0,
      },
    }));
    setTimeout(
      () =>
        this.setState({
          displayStyle: {
            display: "none",
          },
        }),
      500
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  addNewUser = async () => {
    if (
      !this.state.name ||
      !this.state.email ||
      !this.state.password ||
      !this.state.role
    ) {
      alert("No olvides llenar todos los campos.");
    } else if (
      this.state.name.length < 8 ||
      this.state.email.length < 8 ||
      this.state.password.length < 8
    ) {
      alert("Todos los campos deben ser mayores a 8 caracteres.");
    } else if (this.state.password != this.state.confirmpassword) {
      alert("Las contraseñas no coinciden.");
    } else {
      /*alert("El usuario fue creado correctamente.");

            this.setState({
                name: "",
                role: "",
                email: "",
                password: "",
                passwordConfirm: ""
            });

            this.hideUserForm();
            this.getUsers();*/

      const body = {
        name: this.state.name,
        role: this.state.role,
        email: this.state.email,
        password: this.state.password,
        passwordConfirm: this.state.confirmpassword,
      };

      console.log(body);

      const response = await fetch(
        "https://ximet.herokuapp.com/api/v1/users/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
        .then((res) => res.json())
        .then((response) => response);

      console.log(response);

      if (response.status === "success") {
        alert("El usuario fue creado correctamente.");

        this.setState({
          name: "",
          role: "",
          email: "",
          password: "",
          passwordConfirm: "",
        });

        this.hideUserForm();
        this.getUsers();
      } else {
        alert("Ocurrió un error, inténtalo de nuevo.");
      }
    }
  };

  render() {
    const { addNewUser } = this;
    const { displayUserForm } = this;
    const { handleChange } = this;
    const { hideUserForm } = this;

    return (
      <>
        <UsuariosHeader displayUserForm={displayUserForm} />
        <UsuariosTable
          key={this.state.users}
          usersRecords={this.state.usersRecords}
          getUsers={this.getUsers}
        />
        <UsuariosNew
          addNewUser={addNewUser}
          handleChange={handleChange}
          hideUserForm={hideUserForm}
          darkBgStyles={this.state.darkBgStyles}
          displayStyle={this.state.displayStyle}
          sideContainerStyles={this.state.sideContainerStyles}
        />
      </>
    );
  }
}

export default Usuarios;
