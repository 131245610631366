import React from "react";

import { Form, Radio, DatePicker, Cascader } from "antd";

const options = [
  {
    label: "Desfribilador implantable",
    value: "desfribiladorImplantable",
    children: [
      {
        label: "Prevención primaria",
        value: "prevencionPrimaria",
      },
      {
        label: "Prevención secundaria",
        value: "prevencionSecundaria",
      },
    ],
  },
  {
    label: "CRT-D",
    value: "CRT-D",
    children: [
      {
        label: "Prevención primaria",
        value: "prevencionPrimaria",
      },
      {
        label: "Prevención secundaria",
        value: "prevencionSecundaria",
      },
    ],
  },
  {
    label: "CRT-P",
    value: "CRT-P",
  },
  {
    label: "Marcapasos",
    value: "marcapasos",
  },
];

const DispotivoImplantado = ({
  onChangeField,
  onChangeDate,
  state,
  onChangeCascader,
}) => {
  return (
    <>
      <Form.Item label="Fecha de implante" name="fechaDeImplante">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "fechaDeImplante")
          }
          id="fechaDeImplante"
        />
      </Form.Item>
      {/** 
      <Form.Item label="|" name="dispositivoImplantado">
        <Radio.Group onChange={onChangeField} name="dispositivoImplantado">
          <Radio value="desfribriladorImplantable">
            Desfibrilador Implantable
          </Radio>
          <Radio value="CRTD">CRT-D</Radio>
          <Radio value="CRTP">CRT-P</Radio>
          <Radio value="marcapasos">Marcapasos</Radio>
        </Radio.Group>
      </Form.Item>
         */}
      <Form.Item label="Tipo de muerte">
        <Cascader
          options={options}
          onChange={onChangeCascader}
          expandTrigger="hover"
          value={[state.tipoDeMuerte, state.lugarDeMuerte]}
        />
      </Form.Item>
    </>
  );
};

export default DispotivoImplantado;
