import React from "react";
import { Form, Input } from "antd";

import { ruleNumber } from "../../utils/rules";

function SignosVitalesAlIngreso({ onChangeNumber }) {
  return (
    <div>
      <Form.Item
        label="Frecuencia cardíaca(lpm)"
        name="frecuenciaCardiaca"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="frecuenciaCardiaca" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="TA Sistólica( mmHg)"
        name="TASistolica"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="TASistolica" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="TA Diastólica (mmHg)"
        name="TADiastolica"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="TADiastolica" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Peso (kg)"
        name="peso"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="peso" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Saturacion de O2"
        name="saturacionDeODos"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="saturacionDeODos" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
    </div>
  );
}

export default SignosVitalesAlIngreso;
