
import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const Diureticos = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Furosemida Oral"
                checked={state.furosemidaOralCheck}
                input={state.furosemidaOral}
                date={state.furosemidaOralDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="furosemidaOral"
                nameCheck="furosemidaOralCheck"
                nameDate="furosemidaOralDate"
            />
            <Medicamento
                labelName="Furosemida IV"
                checked={state.furosemidaIVCheck}
                input={state.furosemidaIV}
                date={state.furosemidaIVDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="furosemidaIV"
                nameCheck="furosemidaIVCheck"
                nameDate="furosemidaIVDate"
            />
            <Medicamento
                labelName="Bumetanida"
                checked={state.bumetanidaCheck}
                input={state.bumetanida}
                date={state.bumetanidaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="bumetanida"
                nameCheck="bumetanidaCheck"
                nameDate="bumetanidaDate"
            />
            <Medicamento
                labelName="Hidroclorotiazida"
                checked={state.hidroclorotiazidaCheck}
                input={state.hidroclorotiazida}
                date={state.hidroclorotiazidaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="hidroclorotiazida"
                nameCheck="hidroclorotiazidaCheck"
                nameDate="hidroclorotiazidaDate"
            />
            <Medicamento
                labelName="Clortalidona"
                checked={state.clortalidonaCheck}
                input={state.clortalidona}
                date={state.clortalidonaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="clortalidona"
                nameCheck="clortalidonaCheck"
                nameDate="clortalidonaDate"
            />
            <Medicamento
                labelName="lndapamida"
                checked={state.indapamidaCheck}
                input={state.indapamida}
                date={state.indapamidaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="indapamida"
                nameCheck="indapamidaCheck"
                nameDate="indapamidaDate"
            />
            <Medicamento
                labelName="Espironolactona/
                Eplerrenona"
                checked={state.espironolactonaEplerrenonaCheck}
                input={state.espironolactonaEplerrenona}
                date={state.espironolactonaEplerrenonaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="espironolactonaEplerrenona"
                nameCheck="espironolactonaEplerrenonaCheck"
                nameDate="espironolactonaEplerrenonaDate"
            />
            <Medicamento
                labelName="Amilorida"
                checked={state.amiloridaCheck}
                input={state.amilorida}
                date={state.amiloridaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="amilorida"
                nameCheck="amiloridaCheck"
                nameDate="amiloridaDate"
            />
            <Medicamento
                labelName="Triamtereno"
                checked={state.triamterenoCheck}
                input={state.triamtereno}
                date={state.triamterenoDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="triamtereno"
                nameCheck="triamterenoCheck"
                nameDate="triamterenoDate"
            />
            <Medicamento
                labelName="Digoxina"
                checked={state.digoxinaCheck}
                input={state.digoxina}
                date={state.digoxinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="digoxina"
                nameCheck="digoxinaCheck"
                nameDate="digoxinaDate"
            />
            <Medicamento
                labelName="Hidralazina"
                checked={state.hidralazinaCheck}
                input={state.hidralazina}
                date={state.hidralazinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="hidralazina"
                nameCheck="hidralazinaCheck"
                nameDate="hidralazinaDate"
            />
        </>
    );
};

export default Diureticos;