import React from 'react';

import { Radio, Form } from 'antd';

function Desenlace({onChangeField}) {
    return (
        <div>
            <Form.Item
                label="|"
                name="desenlace"
            >
                <Radio.Group
                    name="desenlace"
                    onChange={onChangeField}
                >
                    <Radio value="alta">Alta</Radio>
                    <Radio value="hospitalizacion">Hospitalización</Radio>
                </Radio.Group>
            </Form.Item>
        </div>
    );
}

export default Desenlace;