import React from "react";
import { Form, Input, Checkbox, Radio } from "antd";

import { ruleNumber } from "../../utils/rules";

function UrgenciasComponent({
  onChangeField,
  onChangeNumber,
  onChangeCheck,
  state,
}) {
  return (
    <div>
      <Form.Item
        label="Hospital que le atendió en
                urgencias"
        name="hospitalQueLeAtendioEnUrgencias"
      >
        <Input
          name="hospitalQueLeAtendioEnUrgencias"
          onChange={onChangeField}
        />
      </Form.Item>
      <Form.Item
        label="Horas en urgencias"
        name="horasEnUrgencias"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="horasEnUrgencias" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <span>
        <b>Causa de atención en urgencias</b>
      </span>
      <Form.Item>
        <Checkbox
          checked={state.taquiarritmia}
          name="taquiarritmia"
          onChange={onChangeCheck}
        >
          Taquiarritmia (p. ej., fibrilación auricular, taquicardia ventricular)
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={state.aumentoExcesivoDeLaPresionArterial}
          name="aumentoExcesivoDeLaPresionArterial"
          onChange={onChangeCheck}
        >
          Aumento excesivo de la presión arterial
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={state.infeccion}
          name="infeccion"
          onChange={onChangeCheck}
        >
          Infección (p. ej., neumonía, endocarditis infecciosa, sepsis)
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={state.faltaDeAdherenciaALaRestriccionDeSalOMedicacion}
          name="faltaDeAdherenciaALaRestriccionDeSalOMedicacion"
          onChange={onChangeCheck}
        >
          Falta de adherencia a la restricción de sal/fluidos o medicación
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={state.bradiattirmia}
          name="bradiattirmia"
          onChange={onChangeCheck}
        >
          Bradiarritmia
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={state.choqueDelDispositivoImplantado}
          name="choqueDelDispositivoImplantado"
          onChange={onChangeCheck}
        >
          Choque del dispositivo implantado
        </Checkbox>
      </Form.Item>
      <Form.Item label="|" name="choqueDelDispositivoImplantadoRadio">
        <Radio.Group
          name="choqueDelDispositivoImplantadoRadio"
          onChange={onChangeField}
          disabled={!state.choqueDelDispositivoImplantado}
        >
          <Radio value="apropiado">Apropiado</Radio>
          <Radio value="inapropiado">Inapropiado</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}

export default UrgenciasComponent;
