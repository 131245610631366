import React from "react";

import { Form, Input } from "antd";

function LoginForm({ handleSubmit, handleChange, state }) {
  return (
    <div className="login">
      <div className="container">
        <div className="contForm">
          <div className="logo-login">
            <h3>Clínica de Falla Cardiaca</h3>
          </div>
          <div className="formBx">
            <div className="form signinForm">
              <Form>
                <Input
                  name="email"
                  value={state.email}
                  placeholder="Email"
                  onChange={handleChange}
                />
                <Input
                  name="password"
                  value={state.password}
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                />
                <Input
                  type="submit"
                  value="Iniciar sesión"
                  onClick={handleSubmit}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
