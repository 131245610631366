import React from "react";
import { Form, DatePicker, Radio, Input, Checkbox } from "antd";
import { ruleNumber } from "../../utils/rules";

function HospitalizacionComponent({
  onChangeField,
  onChangeDate,
  onChangeCheck,
  onChangeNumber,
  state,
}) {
  return (
    <div>
      <Form.Item label="Fecha de ingreso" name="fechaDeIngresoHospitalizacion">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) => {
            onChangeDate(date, dateString, "fechaDeIngresoHospitalizacion");
          }}
        />
      </Form.Item>
      <Form.Item label="Fecha de egreso" name="fechaDeEgresoHospitalizacion">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "fechaDeEgresoHospitalizacion")
          }
        />
      </Form.Item>
      <Form.Item
        label="Nombre del Hospital
                donde se hospitalizó"
        name="nombreDelHospitalDondeSeHospitalizo"
      >
        <Input
          name="nombreDelHospitalDondeSeHospitalizo"
          onChange={onChangeField}
        />
      </Form.Item>
      <Form.Item label="Relacionado con IC" name="relacionadoConIC">
        <Radio.Group name="relacionadoConIC" onChange={onChangeField}>
          <Radio value="Si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Días en piso"
        name="diasEnPiso"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="diasEnPiso" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Días en terapia intensiva"
        name="diasEnTerapiaIntensiva"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input
          name="diasEnTerapiaIntensiva"
          onChange={(e) => onChangeNumber(e)}
        />
      </Form.Item>
      <Form.Item
        label="Días totales"
        name="diasTotales"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="diasTotales" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item label="Asistencia ventilatoria" name="asistenciaVentilatoria">
        <Radio.Group name="asistenciaVentilatoria" onChange={onChangeField}>
          <Radio value="noInvasiva">No invasiva</Radio>
          <Radio value="invasiva">Invasiva</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Desenlace hospitalario" name="desenlaceHospitalario">
        <Radio.Group name="desenlaceHospitalario" onChange={onChangeField}>
          <Radio value="alta">Alta</Radio>
          <Radio value="muerte">Muerte</Radio>
          <Radio value="trasladoAOtroHospital">Traslado a otro hospital</Radio>
        </Radio.Group>
      </Form.Item>
      <span>
        <b>Factor desencandenante de hospitalización</b>
      </span>
      <div className="checkBoxContainer">
        <div>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.taquiarritmia}
              name="taquiarritmia"
              onChange={onChangeCheck}
            >
              Taquiarritmia (p. ej., fibrilación auricular, taquicardia
              ventricular)
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.aumentoExcesivoDeLaPresionArterial}
              name="aumentoExcesivoDeLaPresionArterial"
              onChange={onChangeCheck}
            >
              Aumento excesivo de la presión arterial
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.infeccion}
              name="infeccion"
              onChange={onChangeCheck}
            >
              Infección (p. ej., neumonía, endocarditis infecciosa, sepsis)
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.faltaDeAdherenciaALaRestriccionDeSalOMedicacion}
              name="faltaDeAdherenciaALaRestriccionDeSalOMedicacion"
              onChange={onChangeCheck}
            >
              Falta de adherencia a la restricción de sal/fluidos o medicación
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.bradiattirmia}
              name="bradiattirmia"
              onChange={onChangeCheck}
            >
              Bradiarritmia
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              name="sustanciasToxicas"
              checked={state.sustanciasToxicas}
              onChange={onChangeCheck}
            >
              Sustancias tóxicas (alcohol, drogas)
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.farmacos}
              name="farmacos"
              onChange={onChangeCheck}
            >
              Fármacos (p. ej., AINE, corticoides, sustancias inotrópicas
              negativas, quimioterapia cardiotóxica)
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={
                state.exacerbacionDeLaEnfermedadPulmonarObstructivaCronica
              }
              name="exacerbacionDeLaEnfermedadPulmonarObstructivaCronica"
              onChange={onChangeCheck}
            >
              Exacerbación de la enfermedad pulmonar obstructiva crónica
            </Checkbox>
          </Form.Item>
        </div>
        <div>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.emboliaPulmonar}
              name="emboliaPulmonar"
              onChange={onChangeCheck}
            >
              Embolia pulmonar
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.cirugiaYComplicacionesPerioperatorias}
              name="cirugiaYComplicacionesPerioperatorias"
              onChange={onChangeCheck}
            >
              Cirugía y complicaciones perioperatorias
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={
                state.aumentoDelImpulsoSimpaticoMiocardioRelacionadaConEstres
              }
              name="aumentoDelImpulsoSimpaticoMiocardioRelacionadaConEstres"
              onChange={onChangeCheck}
            >
              Aumento del impulso simpático, miocardiopatía relacionada con
              estrés
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.alteracionesHormonalesMetabolicas}
              name="alteracionesHormonalesMetabolicas"
              onChange={onChangeCheck}
            >
              Alteraciones hormonales/metabólicas (p. ej., disfunción tiroidea,
              cetoacidosis diabética, disfunción adrenal, embarazo y
              alteraciones pericárdicas)
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.dañoCerebrovascular}
              name="dañoCerebrovascular"
              onChange={onChangeCheck}
            >
              Daño cerebrovascular
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.causaMecanicaAguda}
              name="causaMecanicaAguda"
              onChange={onChangeCheck}
            >
              Causa mecánica aguda: SCA complicado con rotura miocárdica (rotura
              de la pared libre, comunicación interventricular, regurgitación
              mitral aguda),traumatismo torácico o intervención cardiaca,
              incompetencia de válvula nativa o protésica secundaria a
              endocarditis, disección o trombosis aórtica)
            </Checkbox>
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default HospitalizacionComponent;
