import React from "react";
import { Form, Input, Checkbox, Radio } from "antd";
import { ruleNumber } from "../../utils/rules";

function ExploracionFisica({
  onChangeField,
  onChangeNumber,
  onChangeCheck,
  state,
}) {
  return (
    <div>
      <Form.Item
        label="Frecuencia cardíaca (lpm)"
        name="frecuenciaCardiaca"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="frecuenciaCardiaca" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="TA Sistólica (mmHg)"
        name="TASistolica"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="TASistolica" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="TA Diastólica (mmHg)"
        name="TADiastolica"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="TADiastolica" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Peso (Kg)"
        name="peso"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="peso" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Saturacion de O2"
        name="saturacionDeO2"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="saturacionDeO2" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item label="EGC">
        <Checkbox
          checked={state.EGCCheck}
          name="EGCCheck"
          onChange={onChangeCheck}
        />
      </Form.Item>
      <Form.Item label="|">
        <Input.Group compact>
          <div style={{ width: "25%" }}>QRS</div>
          <Form.Item name="QRS" wrapperCol={{ span: 24 }}>
            <Input
              maxLength={3}
              name="QRS"
              onChange={(value) => onChangeNumber(value, "QRS")}
              disabled={!state.EGCCheck}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="|">
        <Input.Group compact>
          <div style={{ width: "25%" }}>PR</div>
          <Form.Item name="PR" wrapperCol={{ span: 24 }}>
            <Input
              maxLength={3}
              name="PR"
              onChange={(value) => onChangeNumber(value, "PR")}
              disabled={!state.EGCCheck}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="|">
        <Checkbox
          name="FA"
          disabled={!state.EGCCheck}
          checked={state.FA}
          onChange={onChangeCheck}
        >
          FA
        </Checkbox>
      </Form.Item>
      <Form.Item label="Tratamiento con oxígeno">
        <Checkbox
          name="tratamientoConOxigeno"
          checked={state.tratamientoConOxigeno}
          onChange={onChangeCheck}
        />
      </Form.Item>
      <Form.Item label="Tratamiento endovenoso">
        <Checkbox
          name="tratamientoEndovenosoCheck"
          checked={state.tratamientoEndovenosoCheck}
          onChange={onChangeCheck}
        />
      </Form.Item>
      <Form.Item label="|" name="tratamientoEndovenoso">
        <Radio.Group
          name="tratamientoEndovenoso"
          onChange={onChangeField}
          disabled={!state.tratamientoEndovenosoCheck}
        >
          <Radio value="vasodilatadores">Vasodilatadores</Radio>
          <Radio value="diureticos">Diuréticos</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Soporte farmacológico">
        <Checkbox
          name="soporteFarmacologico"
          checked={state.soporteFarmacologico}
          onChange={onChangeCheck}
        />
      </Form.Item>
    </div>
  );
}

export default ExploracionFisica;
