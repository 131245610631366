import React from "react";

import { Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import "./HomeHeader.css";

const HomeHeader = ({ addNewPatient }) => {
  return (
    <div className="HomeHeader">
      <div>
        <h1>Clinica</h1>
      </div>
      <Button onClick={addNewPatient}>
        <UserAddOutlined />
        Nuevo Paciente
      </Button>
    </div>
  );
};

export default HomeHeader;
