import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const BloqueadoresDelCanalIF = ({
  onChangeField,
  onChangeDate,
  onChangeCheck,
  state,
}) => {
  return (
    <>
      <Medicamento
        labelName="Ivabradina"
        checked={state.ivabradinaCheck}
        input={state.ivabradina}
        date={state.ivabradinaDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="ivabradina"
        nameCheck="ivabradinaCheck"
        nameDate="ivabradinaDate"
      />
      <Medicamento
        labelName="Amiodarona"
        checked={state.amiodaronaCheck}
        input={state.amiodarona}
        date={state.amiodaronaDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="amiodarona"
        nameCheck="amiodaronaCheck"
        nameDate="amiodaronaDate"
      />
    </>
  );
};

export default BloqueadoresDelCanalIF;
