import React from "react";

import { Checkbox, Form } from "antd";

const AntecedentesMedicos = ({ onChangeField, onChangeCheck, state }) => {
  return (
    <div className="checkBoxContainer">
      <div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.hipertensionCheck}
            onChange={onChangeCheck}
            name="hipertensionCheck"
          >
            Hipertensión
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.diabetesAntecedentesMedicosCheck}
            onChange={onChangeCheck}
            name="diabetesAntecedentesMedicosCheck"
          >
            Diabetes
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.disfuncionRenalCheck}
            onChange={onChangeCheck}
            name="disfuncionRenalCheck"
          >
            Disfunción renal
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.tabaquismoCheck}
            onChange={onChangeCheck}
            name="tabaquismoCheck"
          >
            Tabaquismo
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.alcoholismoCheck}
            onChange={onChangeCheck}
            name="alcoholismoCheck"
          >
            Alcoholismo ( {">"} 1 bebida al día)
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.infartoAlMiocardioPrevioCheck}
            onChange={onChangeCheck}
            name="infartoAlMiocardioPrevioCheck"
          >
            Infarto al miocardio previo
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.fibrilacionAtrialCheck}
            onChange={onChangeCheck}
            name="fibrilacionAtrialCheck"
          >
            Fibrilación atrial
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.enfermedadPulmonarCronicaCheck}
            onChange={onChangeCheck}
            name="enfermedadPulmonarCronicaCheck"
          >
            Enfermedad pulmonar crónica
          </Checkbox>
        </Form.Item>
      </div>
      <div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.disfuncionErectilCheck}
            onChange={onChangeCheck}
            name="disfuncionErectilCheck"
          >
            Disfunción eréctil
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.artritisReumatoideCheck}
            onChange={onChangeCheck}
            name="artritisReumatoideCheck"
          >
            Artritis reumatoide
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.gotaCheck}
            onChange={onChangeCheck}
            name="gotaCheck"
          >
            Gota
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.anemiaCheck}
            onChange={onChangeCheck}
            name="anemiaCheck"
          >
            Anemia
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.trastornosDelSueñoCheck}
            onChange={onChangeCheck}
            name="trastornosDelSueñoCheck"
          >
            Trastornos del sueño
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.hipertiroidismoCheck}
            onChange={onChangeCheck}
            name="hipertiroidismoCheck"
          >
            Hipertiroidismo
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.hipotiroidismoCheck}
            onChange={onChangeCheck}
            name="hipotiroidismoCheck"
          >
            Hipotiroidismo
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.cancerCheck}
            onChange={onChangeCheck}
            name="cancerCheck"
          >
            Cancer
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.VIHCheck}
            onChange={onChangeCheck}
            name="VIHCheck"
          >
            VIH / SIDA
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

export default AntecedentesMedicos;
