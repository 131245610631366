import React from "react";

import { Form, DatePicker } from "antd";

function SiguienteConsulta({ onChangeDate, state }) {
  return (
    <Form.Item label="Siguiente Consulta" name="SiguienteConsulta">
      <DatePicker
        format="DD-MM-YYYY"
        onChange={(date, dateString) => {
          onChangeDate(dateString, "siguienteConsulta");
        }}
      />
    </Form.Item>
  );
}

export default SiguienteConsulta;
