import React, { useEffect, useState } from "react";

import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import { ContainerOutlined, DeleteOutlined } from "@ant-design/icons";
import HomeModal from "../components/HomeModal";

import "./HomeTable.css";
import DeletePacienteModal from "./DeletePacienteModal";

const HomeHeader = ({ patientsRecords, getPatients }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFormType, setCurrentFormType] = useState("");
  const [currentPatient, setCurrentPatient] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentDeletePaciente, setCurrentDeletePaciente] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (currentFormType.length !== 0 && patientsRecords.length !== 0) {
      const updatedRecord = patientsRecords.find(
        (patient) => patient.PacienteID === currentPatient.id_paciente
      );
      setCurrentPatient(updatedRecord[currentFormType]);
    }
  }, [patientsRecords, currentFormType, currentPatient.id_paciente]);

  const patient = (props) => {
    return props.records.map((record) => {
      return {
        key: record.id,
        ID: record.id,
        [currentFormType]: { id: record.id, id_paciente: props.id_paciente },
        fecha: new Date(record.created).toLocaleDateString(),
      };
    });
  };

  useEffect(() => {
    if (currentFormType.length !== 0) {
      setDataSource(patient(currentPatient));
    }
  }, [currentFormType, currentPatient]);

  const columns = [
    {
      dataIndex: "ID",
      sorter: (a, b) => a.ID - b.ID,
      className: "table-header",
    },
    {
      title: "Paciente ID",
      dataIndex: "PacienteID",
      className: "table-header",
    },
    {
      title: "Basal",
      dataIndex: "basal",
      className: "table-header",
      render: (id_paciente) => (
        <div className="column-icon">
          <Link to={`/basal/${id_paciente}`}>
            <ContainerOutlined className="icon" />
          </Link>
        </div>
      ),
    },
    {
      title: "Consulta",
      dataIndex: "Consulta",
      className: "table-header",
      render: (props) => (
        <div className="column-icon">
          <Button
            onClick={() => {
              showModal();
              setCurrentFormType("Consulta");
              setCurrentPatient(props);
            }}
          >
            <ContainerOutlined className="icon" />
          </Button>
        </div>
      ),
    },
    {
      title: (
        <div>
          Hospitalización <br /> / Intervención
        </div>
      ),
      dataIndex: "Hospitalizacion",
      className: "table-header",
      render: (props) => (
        <div className="column-icon">
          <Button
            onClick={() => {
              showModal();
              setCurrentFormType("Hospitalizacion");
              setCurrentPatient(props);
            }}
          >
            <ContainerOutlined className="icon" />
          </Button>
        </div>
      ),
    },
    {
      title: "Urgencia",
      dataIndex: "Urgencia",
      className: "table-header",
      render: (props) => (
        <div className="column-icon">
          <Button
            onClick={() => {
              showModal();
              setCurrentFormType("Urgencia");
              setCurrentPatient(props);
            }}
          >
            <ContainerOutlined className="icon" />
          </Button>
        </div>
      ),
    },
    {
      title: "Desenlace",
      dataIndex: "desenlace",
      className: "table-header",
      render: (id_paciente) => (
        <div className="column-icon">
          <Link to={`/desenlace/${id_paciente}`}>
            <ContainerOutlined className="icon" />
          </Link>
        </div>
      ),
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      className: "table-header",
      filters: [
        {
          text: "Enero",
          value: "1/",
        },
        {
          text: "Febrero",
          value: "2/",
        },
        {
          text: "Marzo",
          value: "3/",
        },

        {
          text: "Abril",
          value: "4/",
        },
        {
          text: "Mayo",
          value: "5/",
        },
        {
          text: "Junio",
          value: "6/",
        },
        {
          text: "Julio",
          value: "7/",
        },
        {
          text: "Agosto",
          value: "8/",
        },
        {
          text: "Septiembre",
          value: "9/",
        },
        {
          text: "Octubre",
          value: "10/",
        },
        {
          text: "Noviembre",
          value: "11/",
        },
        {
          text: "Diciembre",
          value: "12/",
        },
      ],
      onFilter: (value, record) => {
        return record.fecha.includes(value);
      },
      filterSearch: true,
    },
    {
      title: "Borrar",
      dataIndex: "borrar",
      key: "borrar",
      className: "table-header",
      render: (id_paciente) => (
        <div className="column-icon">
          <DeleteOutlined
            style={{ cursor: "pointer", color: "red" }}
            className="icon"
            onClick={() => {
              setCurrentDeletePaciente(id_paciente);
              setIsDeleteModalVisible(!isDeleteModalVisible);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={patientsRecords} />
      <HomeModal
        currentFormType={currentFormType}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        patientsRecords={patientsRecords}
        currentPatient={currentPatient}
        dataSource={dataSource}
        getPatients={getPatients}
        setCurrentPatient={setCurrentPatient}
      />
      <DeletePacienteModal
        setIsDeleteModalVisible={setIsDeleteModalVisible}
        isDeleteModalVisible={isDeleteModalVisible}
        currentDeletePaciente={currentDeletePaciente}
        getPatients={getPatients}
      />
    </>
  );
};

export default HomeHeader;
