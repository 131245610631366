import React from "react";

import { Input, DatePicker, Checkbox, Form } from "antd";
import { NodeExpandOutlined } from "@ant-design/icons";

const MedicamentoDisabled = ({
  labelName,
  checked,
  onChangeCheck,
  nameCheck,
  input,
  onChangeField,
  name,
  date,
  onChangeDate,
  nameDate,
  state,
}) => {
  if (name.includes("carvedilol")) console.log(input);
  return (
    <Input.Group compact>
      <div style={{ width: "20%", backgroundColor: "none" }}>{labelName}</div>
      <Form.Item style={{ width: "5%" }}>
        <Checkbox
          style={{ backgroundColor: "none" }}
          className={input && "medicamentoDisabled"}
          checked={checked}
          onChange={onChangeCheck}
          name={nameCheck}
          disabled={true}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }} name={name}>
        <Input
          placeholder="dosis mg"
          onChange={onChangeField}
          name={name}
          className={input && "medicamentoDisabled"}
          disabled={true}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }} name={nameDate}>
        <DatePicker
          format="DD-MM-YYYY"
          className={input && "medicamentoDisabled"}
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, nameDate)
          }
          disabled={true}
        />
      </Form.Item>
    </Input.Group>
  );
};

export default MedicamentoDisabled;
