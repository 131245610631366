import React, { Component } from "react";
import moment from "moment";

import { Form, Collapse, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Header from "../../shared/components/layout/Header";
import GeneralDataUrgencias from "../components/GeneralDataUrgencias";
import EstudiosDeGabinete from "../components/EstudiosDeGabinete";
import ExploracionFisica from "../components/ExploracionFisica";
import UrgenciasComponent from "./../components/UrgenciasComponent";
import Desenlace from "../components/Desenlace";
import withParams from "../../hooks/withParams";
import { onChangeNum } from "../../utils/onChangeNum";

const { Panel } = Collapse;

class Urgencias extends Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.form = "urgency";
    this.state = {};
  }

  componentDidMount = async () => {
    const { id_paciente, id } = this.props.params;
    let urgency = await fetch(
      `https://ximet.herokuapp.com/api/v1/urgency/${id_paciente}/${id}`
    )
      .then((res) => res.json())
      .then((response) => response.data);

    if (urgency) {
      Object.keys(urgency).forEach((key) => {
        if (
          (key.includes("Date") ||
            key.includes("date") ||
            key.includes("fecha") ||
            key.includes("nacimiento")) &&
          urgency[key] !== null
        ) {
          urgency[key] = moment(urgency[key]);
        }
      });

      console.log(urgency);
      this.ref.current.setFieldsValue({
        ...urgency,
      });

      this.setState({ ...urgency });
    }
  };

  onChangeField = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => console.log({ [e.target.name]: e.target.value })
    );
  };

  onChangeCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onChangeNumber = (e) => {
    onChangeNum(this, e);
  };

  onChangeDate = (date, dateString, name) => {
    this.setState({
      [name]: dateString,
    });
  };

  onFinish = async (values) => {
    const { id_paciente, id } = this.props.params;
    const response = await fetch(
      `https://ximet.herokuapp.com/api/v1/urgency/${id_paciente}/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(this.state),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        res.json();
      })
      .then((json) => json);

    Modal.success({
      title: "Has guardado correctamente!",
    });
  };

  showWarningModal = () => {
    const clearAll = () => {
      let newState = {};
      Object.keys(this.state).forEach((key) => {
        newState[key] = null;
        if (
          key === "id_paciente" ||
          key === "id" ||
          key === "nacimiento" ||
          key === "cuatroUltimosDigitosDeLaCedula" ||
          key === "numeroDeUrgencias"
        ) {
          newState[key] = this.state[key];
        }
      });
      this.setState(newState, () => console.log(this.state));
      this.ref.current.resetFields();
      this.ref.current.setFieldsValue({
        id_paciente: this.props.params.id_paciente,
        id: this.state.id,
        nacimiento: this.state.nacimiento,
        cuatroUltimosDigitosDeLaCedula:
          this.state.cuatroUltimosDigitosDeLaCedula,
        numeroDeUrgencias: this.state.numeroDeUrgencias,
      });
    };

    Modal.confirm({
      title: "Espera!",
      icon: <ExclamationCircleOutlined />,
      content: `Seguro que quieres borrar todos los campos?`,
      okText: "Borrar",
      cancelText: "Cancelar",
      onOk() {
        return clearAll();
      },
      onCancel() {
        return;
      },
    });
  };

  render() {
    const {
      onChangeField,
      onChangeCheck,
      onChangeDate,
      onChangeNumber,
      onFinish,
      state,
      showWarningModal,
    } = this;
    return (
      <div>
        <Header
          state={state}
          formType={this.form}
          id_paciente={this.props.params.id_paciente}
          formID={this.props.params.id}
          clearAll={showWarningModal}
        />
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          ref={this.ref}
          onFinish={onFinish}
        >
          <GeneralDataUrgencias
            onChangeField={onChangeField}
            onChangeCheck={onChangeCheck}
            onChangeDate={onChangeDate}
            onChangeNumber={onChangeNumber}
            state={state}
          />
          <Collapse bordered={false}>
            <Panel forceRender={true} header="Urgencia" key="0">
              <UrgenciasComponent
                onChangeField={onChangeField}
                onChangeCheck={onChangeCheck}
                onChangeDate={onChangeDate}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={true} header="Estudios de gabinete" key="1">
              <EstudiosDeGabinete
                onChangeField={onChangeField}
                onChangeCheck={onChangeCheck}
                onChangeDate={onChangeDate}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={true} header="Exploración física" key="2">
              <ExploracionFisica
                onChangeField={onChangeField}
                onChangeCheck={onChangeCheck}
                onChangeDate={onChangeDate}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={true} header="Desenlace" key="3">
              <Desenlace
                onChangeField={onChangeField}
                onChangeCheck={onChangeCheck}
                onChangeDate={onChangeDate}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
          </Collapse>
          <button id={this.form} type="submit" hidden={true}>
            SUBMIT
          </button>
        </Form>
      </div>
    );
  }
}

export default withParams(Urgencias);
