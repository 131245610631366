import React from "react";

import { Form, Checkbox, Button, Switch } from "antd";

const Sintomas = ({ onToggleSintomas, onChangeCheck, state }) => {
  return (
    <div className="checkBoxContainer">
      <div>
        <Form.Item
          label="No tiene Síntomas"
          labelCol={{ span: 24 }}
          name="noTieneSintomas"
        >
          <Switch
            name="noTieneSintomas"
            onClick={(e) => {
              onToggleSintomas(e);
            }}
            checked={state.noTieneSintomas}
          />
        </Form.Item>
      </div>

      <div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            className="check"
            checked={state.disneaCheck}
            onChange={onChangeCheck}
            name="disneaCheck"
            disabled={state.noTieneSintomas}
          >
            Disnea
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.ortopneaCheck}
            onChange={onChangeCheck}
            name="ortopneaCheck"
          >
            Ortopnea
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.disneaParoxisticaNocturnaCheck}
            onChange={onChangeCheck}
            name="disneaParoxisticaNocturnaCheck"
          >
            Disnea paroxística nocturna
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.toleranciaAlEjercicioDisminuidaCheck}
            onChange={onChangeCheck}
            name="toleranciaAlEjercicioDisminuidaCheck"
          >
            Tolerancia al ejercicio disminuida
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={
              state.fatigaCansancioMasTiempoHastaRecuperarseDelEjercicioCheck
            }
            onChange={onChangeCheck}
            name="fatigaCansancioMasTiempoHastaRecuperarseDelEjercicioCheck"
          >
            Fatiga, cansancio, más tiempo hasta recuperarse del ejercicio
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.edemaDeTobillosCheck}
            onChange={onChangeCheck}
            name="edemaDeTobillosCheck"
          >
            Edema de tobillos
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.tosNocturnaCheck}
            onChange={onChangeCheck}
            name="tosNocturnaCheck"
          >
            Tos nocturna
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.sibilanciasCheck}
            onChange={onChangeCheck}
            name="sibilanciasCheck"
          >
            Sibilancias
          </Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.sensacionDeHinchazonCheck}
            onChange={onChangeCheck}
            name="sensacionDeHinchazonCheck"
          >
            Sensación de hinchazón
          </Checkbox>
        </Form.Item>
      </div>
      <div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.perdidaDeApetitoCheck}
            onChange={onChangeCheck}
            name="perdidaDeApetitoCheck"
          >
            Pérdida de apetito
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.confusionCheck}
            onChange={onChangeCheck}
            name="confusionCheck"
          >
            Confusión (especialmente en ancianos)
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.decaimientoCheck}
            onChange={onChangeCheck}
            name="decaimientoCheck"
          >
            Decaimiento
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.palpitacionesCheck}
            onChange={onChangeCheck}
            name="palpitacionesCheck"
          >
            Palpitaciones
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.mareoCheck}
            onChange={onChangeCheck}
            name="mareoCheck"
          >
            Marea
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.sincopeCheck}
            onChange={onChangeCheck}
            name="sincopeCheck"
          >
            Síncope
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.bendopneaCheck}
            onChange={onChangeCheck}
            name="bendopneaCheck"
          >
            Bendopnea
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            disabled={state.noTieneSintomas}
            checked={state.anginaCheck}
            onChange={onChangeCheck}
            name="anginaCheck"
          >
            Angina
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

export default Sintomas;
