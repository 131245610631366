import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const Vasodilatadores = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Nitroglicerina"
                checked={state.nitroglicerinaololAnteriorCheck}
                input={state.nitroglicerinaololAnterior}
                date={state.nitroglicerinaololAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nitroglicerinaAnterior"
                nameCheck="nitroglicerinaAnteriorCheck"
                nameDate="nitroglicerinaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Dinitrato de
                isosorbida"
                checked={state.dinitratoDeIsosorbidaAnteriorCheck}
                input={state.dinitratoDeIsosorbidaAnterior}
                date={state.dinitratoDeIsosorbidaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dinitratoDeIsosorbidaAnterior"
                nameCheck="dinitratoDeIsosorbidaAnteriorCheck"
                nameDate="dinitratoDeIsosorbidaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Nitroprusiato"
                checked={state.nitroprusiatoAnteriorCheck}
                input={state.nitroprusiatoAnterior}
                date={state.nitroprusiatoAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nitroprusiatoAnterior"
                nameCheck="nitroprusiatoAnteriorCheck"
                nameDate="nitroprusiatoAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Nesiritida"
                checked={state.nesiritidaAnteriorCheck}
                input={state.nesiritidaAnterior}
                date={state.nesiritidaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nesiritidaAnterior"
                nameCheck="nesiritidaAnteriorCheck"
                nameDate="nesiritidaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Noradrenalina"
                checked={state.noradrenalinaAnteriorCheck}
                input={state.noradrenalinaAnterior}
                date={state.noradrenalinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="noradrenalinaAnterior"
                nameCheck="noradrenalinaAnteriorCheck"
                nameDate="noradrenalinaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Epinefrina"
                checked={state.epinefrinaAnteriorCheck}
                input={state.epinefrinaAnterior}
                date={state.epinefrinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="epinefrinaAnterior"
                nameCheck="epinefrinaAnteriorCheck"
                nameDate="epinefrinaAnteriorDate"
            />
        </>
    );
};

export default Vasodilatadores;