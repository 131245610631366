import React from "react";

import { Form, DatePicker, Cascader } from "antd";
import "./Muerte.css";

const options = [
  {
    label: "Cardiaca no súbita",
    value: "cardiacaNoSubita",
    children: [
      {
        label: "Domicilio",
        value: "domicilio",
      },
      {
        label: "En esta institución",
        value: "enEstaInstitucion",
      },
      {
        label: "En otra institución",
        value: "enOtraInstitucion",
      },
      {
        label: "Se desconoce",
        value: "seDesconoce",
      },
    ],
  },
  {
    label: "Cardiaca súbita",
    value: "cardiacaSubita",
    children: [
      {
        label: "Domicilio",
        value: "domicilio",
      },
      {
        label: "En esta institución",
        value: "enEstaInstitucion",
      },
      {
        label: "En otra institución",
        value: "enOtraInstitucion",
      },
      {
        label: "Se desconoce",
        value: "seDesconoce",
      },
    ],
  },
  {
    label: "No cardiaca",
    value: "noCardiaca",
    children: [
      {
        label: "Domicilio",
        value: "domicilio",
      },
      {
        label: "En esta institución",
        value: "enEstaInstitucion",
      },
      {
        label: "En otra institución",
        value: "enOtraInstitucion",
      },
      {
        label: "Se desconoce",
        value: "seDesconoce",
      },
    ],
  },
  {
    label: "Relacionada con procedimiento cardiovascular",
    value: "relacionadaConProcedimientoCardiovascular",
    children: [
      {
        label: "Domicilio",
        value: "domicilio",
      },
      {
        label: "En esta institución",
        value: "enEstaInstitucion",
      },
      {
        label: "En otra institución",
        value: "enOtraInstitucion",
      },
      {
        label: "Se desconoce",
        value: "seDesconoce",
      },
    ],
  },
];

function Muerte({ onChangeField, onChangeDate, onChangeCascader, state }) {
  return (
    <div>
      <div className="cascader">
        <h2>
          <b>Muerte</b>
        </h2>
      </div>
      <Form.Item label="Fecha de muerte" name="fechaDeMuerte">
        <DatePicker
          format="DD-MM-YYYY"
          name="fechaDeMuerte"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "fechaDeMuerte")
          }
        />
      </Form.Item>
      <Form.Item label="Tipo de muerte">
        <Cascader
          options={options}
          onChange={onChangeCascader}
          expandTrigger="hover"
          value={[state.tipoDeMuerte, state.lugarDeMuerte]}
        />
      </Form.Item>
    </div>
  );
}

export default Muerte;
