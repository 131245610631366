import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const INRA = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Sacubitrilo/Valsartán"
                checked={state.sacubitriloValsartanAnteriorCheck}
                input={state.sacubitriloValsartanAnterior}
                date={state.sacubitriloValsartanAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="sacubitriloValsartanAnterior"
                nameCheck="sacubitriloValsartanAnteriorCheck"
                nameDate="sacubitriloValsartanAnteriorDate"
            />
        </>
    );
};

export default INRA;