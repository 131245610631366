import React from "react";

import { Form, Input, DatePicker, Radio } from "antd";
import "./DatosGeneralesDesenlace.css";

function DatosGeneralesDesenlace({ onChangeField, onChangeDate, state }) {
  return (
    <div>
      <Form.Item label="ID" name="cedula">
        <Input onChange={onChangeField} name="cedula" disabled={true} />
      </Form.Item>
      <Form.Item label="Fecha de nacimiento" name="nacimiento">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "nacimiento")
          }
          name="nacimiento"
          disabled={true}
        />
      </Form.Item>
      <Form.Item
        name="datosGeneralesDesenlace"
        onChange={(e) => {
          console.log(e);
        }}
      >
        <Radio.Group
          className="radioContainer"
          name="datosGeneralesDesenlace"
          onChange={onChangeField}
        >
          <Radio value="seRefiereACuidadosPaliativos">
            Se refiere a cuidados paliativos, no regresa a la clínica de FC
          </Radio>
          <Radio value="seRefiereAOtroHospital">
            Se refiere a otro hospital, no regresa a la clínica de FC
          </Radio>
          <Radio value="otroCheck">Otro</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 4 }}
        label="Otro"
        name="otro"
      >
        <Input
          disabled={
            state.datosGeneralesDesenlace === "otroCheck" ? false : true
          }
          name="otro"
          onChange={onChangeField}
        />
      </Form.Item>
    </div>
  );
}

export default DatosGeneralesDesenlace;
