import React from "react";
import { connect } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ScatterChart,
  ZAxis,
  Scatter,
  ResponsiveContainer,
} from "recharts";

import ChartLine from "../components/ChartLine";
import ChartBar from "../components/ChartBar";
import {
  UserOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UserDeleteOutlined,
  FolderOutlined,
} from "@ant-design/icons";

import "./Dashboard.css";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
  {
    name: "Group E",
    value: 278,
  },
  {
    name: "Group F",
    value: 189,
  },
];
const data02 = [
  {
    name: "Group A",
    value: 2400,
  },
  {
    name: "Group B",
    value: 4567,
  },
  {
    name: "Group C",
    value: 1398,
  },
  {
    name: "Group D",
    value: 9800,
  },
  {
    name: "Group E",
    value: 3908,
  },
  {
    name: "Group F",
    value: 4800,
  },
];

const datascat01 = [
  {
    x: 100,
    y: 200,
    z: 200,
  },
  {
    x: 120,
    y: 100,
    z: 260,
  },
  {
    x: 170,
    y: 300,
    z: 400,
  },
  {
    x: 140,
    y: 250,
    z: 280,
  },
  {
    x: 150,
    y: 400,
    z: 500,
  },
  {
    x: 110,
    y: 280,
    z: 200,
  },
];
const datascat02 = [
  {
    x: 200,
    y: 260,
    z: 240,
  },
  {
    x: 240,
    y: 290,
    z: 220,
  },
  {
    x: 190,
    y: 290,
    z: 250,
  },
  {
    x: 198,
    y: 250,
    z: 210,
  },
  {
    x: 180,
    y: 280,
    z: 260,
  },
  {
    x: 210,
    y: 220,
    z: 230,
  },
];

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = async () => {
    const recordsCount = await fetch(
      "https://ximet.herokuapp.com/api/v1/query/count/last12months"
    )
      .then((res) => res.json())
      .then((json) => json.data)
      .catch((err) => console.log(err));

    const hospiVsEdad = await fetch(
      "https://ximet.herokuapp.com/api/v1/query/count/hospitalizationEdad"
    )
      .then((res) => res.json())
      .then((json) => json.data)
      .catch((err) => console.log(err));

    const pacientesVsGenero = await fetch(
      "https://ximet.herokuapp.com/api/v1/query/count/pacientesGenero"
    )
      .then((res) => res.json())
      .then((json) => json.data)
      .catch((err) => console.log(err));

    const totalFallecidos = await fetch(
      "https://ximet.herokuapp.com/api/v1/outcomes/total"
    )
      .then((res) => res.json())
      .then((json) => json.data)
      .catch((err) => console.log(err));

    this.setState({
      recordsCount,
      hospiVsEdad,
      pacientesVsGenero,
      totalFallecidos,
    });
  };

  render() {
    const { recordsCount, hospiVsEdad, pacientesVsGenero, totalFallecidos } =
      this.state;

    var totalHospitalizaciones = 0;
    var totalConsultas = 0;
    var totalUrgencias = 0;
    for (var i = this.props.pacientes.length - 1; i >= 0; i--) {
      totalHospitalizaciones =
        totalHospitalizaciones +
        this.props.pacientes[i].Hospitalizacion.records.length;
      totalConsultas =
        totalConsultas + this.props.pacientes[i].Consulta.records.length;
      totalUrgencias =
        totalUrgencias + this.props.pacientes[i].Urgencia.records.length;
    }

    return (
      <div className="dashboard">
        <div className="cards-container">
          <div className="card">
            <UserOutlined />
            <div>
              <h4>{this.props.pacientes.length}</h4>
              <span>Total de pacientes</span>
            </div>
          </div>
          <div className="card">
            <UserAddOutlined />
            <div>
              <h4>{totalHospitalizaciones}</h4>
              <span>Total de hospitalizaciones</span>
            </div>
          </div>
          <div className="card">
            <FolderOutlined />
            <div>
              <h4>{totalConsultas}</h4>
              <span>Total de consultas</span>
            </div>
          </div>
          <div className="card">
            <UserSwitchOutlined />
            <div>
              <h4>{totalUrgencias}</h4>
              <span>Total de urgencias</span>
            </div>
          </div>
          <div className="card">
            <UserDeleteOutlined />
            <div>
              <h4>{totalFallecidos}</h4>
              <span>Total Fallecidos</span>
            </div>
          </div>
        </div>
        <div className="charts">
          <div className="chart-group group1">
            <div className="chart general">
              <h3>Vista anual general</h3>
              <ChartLine
                data={recordsCount}
                dataKeys={{
                  x: "monthName",
                  y: [
                    "pacientes",
                    "consultas",
                    "urgencias",
                    "hospitalizaciones",
                    "muertes",
                  ],
                }}
              />
            </div>
            <div className="chart gender">
              <h3>Comparación por género</h3>
              <ChartBar
                data={pacientesVsGenero}
                dataKeys={{ x: "genero", y: "pacientes" }}
              />
            </div>
          </div>
          <div className="chart-group group2">
            <div className="chart weight">
              <h3>Estadísticas de peso</h3>
              <div className="center">
                <div className="chart-cont pie-chart">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={data01}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={50}
                        fill="#8884d8"
                      />
                      <Pie
                        data={data02}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#82ca9d"
                        label
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="chart-cont scatter-chart">
                  <ResponsiveContainer>
                    <ScatterChart
                      margin={{ top: 20, right: 20, bottom: 10, left: 10 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="x" name="stature" unit="cm" />
                      <YAxis dataKey="y" name="weight" unit="kg" />
                      <ZAxis
                        dataKey="z"
                        range={[64, 144]}
                        name="score"
                        unit="km"
                      />
                      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                      <Legend />
                      <Scatter
                        name="A school"
                        data={datascat01}
                        fill="#8884d8"
                      />
                      <Scatter
                        name="B school"
                        data={datascat02}
                        fill="#82ca9d"
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="chart-group group3">
            <div className="chart comparative">
              <h3>Comparativas</h3>
              <div className="chart-cont radar-chart">
                <ResponsiveContainer>
                  <RadarChart
                    outerRadius={90}
                    width={730}
                    height={250}
                    data={data}
                  >
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <PolarRadiusAxis angle={30} domain={[0, 150]} />
                    <Radar
                      name="Mike"
                      dataKey="uv"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                    <Radar
                      name="Lily"
                      dataKey="pv"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                      fillOpacity={0.6}
                    />
                    <Legend />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="chart hospitalizations">
              <h3>Tabla de hospitalizaciones</h3>
              <ChartBar
                data={hospiVsEdad}
                dataKeys={{ x: "intervalo", y: "hospitalizaciones" }}
              />
            </div>
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

const mapStateToProps = ({ pacientes }) => {
  return {
    pacientes: pacientes,
  };
};

export default connect(mapStateToProps)(Dashboard);
