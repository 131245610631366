import React, { useContext, useState } from "react";

const UserContext = React.createContext();
const UserUpdateContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function useUserUpdate() {
  return useContext(UserUpdateContext);
}

const Context = ({ children }) => {
  const [user, setUser] = useState({
    id: 2,
    email: "marco1@marco.com",
    name: "Marco Berumen",
    role: "enfermero",
    estado: true,
    isLoggedIn: true,
  });

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={setUser}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};

export default Context;
