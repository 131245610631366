import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const INRA = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Sacubitrilo/Valsartán"
                checked={state.sacubitriloValsartanCheck}
                input={state.sacubitriloValsartan}
                date={state.sacubitriloValsartanDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="sacubitriloValsartan"
                nameCheck="sacubitriloValsartanCheck"
                nameDate="sacubitriloValsartanDate"
            />
        </>
    );
};

export default INRA;