import React, { Component } from "react";

import moment from "moment";
import { Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Header from "../../shared/components/layout/Header";
import DatosGeneralesDesenlace from "../components/DatosGeneralesDesenlace";
import Muerte from "../components/Muerte";
import withParams from "../../hooks/withParams";

class Desenlace extends Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.form = "outcomes";
    this.state = {
      id_paciente: "0001",
      //Datos Generales
      cedula: "123",
      nacimiento: moment("2022-10-02"),
      datosGeneralesDesenlace: "123",
      otro: "123",
      //Muerte
      fechaDeMuerte: moment("2022-10-02"),
      tipoDeMuerte: "noCardiaca",
      lugarDeMuerte: "enOtraInstitucion",
    };
  }

  componentDidMount = async () => {
    let desenlace = await fetch(
      `https://ximet.herokuapp.com/api/v1/outcomes/${this.props.params.id_paciente}`
    )
      .then((res) => res.json())
      .then((response) => response.data);

    if (desenlace) {
      console.log(desenlace);
      await Object.keys(desenlace).forEach((key) => {
        if (
          (key.includes("Date") ||
            key.includes("date") ||
            key.includes("fecha") ||
            key.includes("nacimiento")) &&
          desenlace[key] !== null
        ) {
          desenlace[key] = moment(desenlace[key]);
        }
      });

      this.ref.current.setFieldsValue({
        ...desenlace,
      });

      this.setState({ ...desenlace });
    }
  };

  onChangeField = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeDate = (date, dateString, id) => {
    this.setState({
      [id]: dateString,
    });
  };

  onChangeCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onChangeNumber = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onChangeCascader = (array) => {
    this.setState({
      tipoDeMuerte: array[0],
      lugarDeMuerte: array[1],
    });
  };

  onFinish = async (values) => {
    console.log({ values, state: this.state });
    const response = await fetch(
      `https://ximet.herokuapp.com/api/v1/outcomes/${this.props.params.id_paciente}`,
      {
        method: "PUT",
        body: JSON.stringify(this.state),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        res.json();
      })
      .then((json) => json);

    Modal.success({
      title: "Has guardado correctamente!",
    });
  };

  showWarningModal = () => {
    const clearAll = () => {
      let newState = {};
      Object.keys(this.state).forEach((key) => {
        newState[key] = null;
        if (
          key === "id_paciente" ||
          key === "id" ||
          key === "nacimiento" ||
          key === "cedula"
        ) {
          newState[key] = this.state[key];
        }
      });
      this.setState(newState, () => console.log(this.state));
      this.ref.current.resetFields();
      this.ref.current.setFieldsValue({
        id_paciente: this.props.params.id_paciente,
        id: this.state.id,
        nacimiento: this.state.nacimiento,
        cedula: this.state.cedula,
      });
    };

    Modal.confirm({
      title: "Espera!",
      icon: <ExclamationCircleOutlined />,
      content: `Seguro que quieres borrar todos los campos?`,
      okText: "Borrar",
      cancelText: "Cancelar",
      onOk() {
        return clearAll();
      },
      onCancel() {
        return;
      },
    });
  };

  render() {
    const {
      onChangeCascader,
      onChangeField,
      onChangeDate,
      onChangeCheck,
      onChangeNumber,
      onFinish,
      state,
      showWarningModal,
    } = this;

    return (
      <div>
        <Header
          state={state}
          formType={this.form}
          id_paciente={this.props.params.id_paciente}
          clearAll={showWarningModal}
        />
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          ref={this.ref}
          onFinish={onFinish}
        >
          <DatosGeneralesDesenlace
            onChangeField={onChangeField}
            onChangeDate={onChangeDate}
            onChangeCheck={onChangeCheck}
            onChangeNumber={onChangeNumber}
            state={state}
          />
          <Muerte
            onChangeField={onChangeField}
            onChangeDate={onChangeDate}
            onChangeCheck={onChangeCheck}
            onChangeNumber={onChangeNumber}
            onChangeCascader={onChangeCascader}
            state={state}
          />
          <button id={this.form} hidden={true} type="submit">
            Submit
          </button>
        </Form>
      </div>
    );
  }
}

export default withParams(Desenlace);
