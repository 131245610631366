import React, { useState } from "react";

import { Table } from "antd";
import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import UsuariosDeleteModal from "./UsuariosDeleteModal";

import "./UsuariosTable.css";
const UsuariosHeader = ({ usersRecords, getUsers }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const columns = [
    {
      dataIndex: "ID",
      sorter: (a, b) => a.ID - b.ID,
      className: "table-header",
    },
    {
      title: "Nombre",
      dataIndex: "Name",
      className: "table-header",
    },
    {
      title: "Email",
      dataIndex: "Email",
      className: "table-header",
    },
    {
      title: "Rol",
      dataIndex: "Role",
      className: "table-header",
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      className: "table-header",
      filters: [
        {
          text: "Enero",
          value: "0",
        },
        {
          text: "Febrero",
          value: "Febrero",
        },
        {
          text: "Marzo",
          value: "Marzo",
        },

        {
          text: "Abril",
          value: "Abril",
        },
        {
          text: "Mayo",
          value: "Mayo",
        },
        {
          text: "Junio",
          value: "Junio",
        },
        {
          text: "Julio",
          value: "Julio",
        },
        {
          text: "Agosto",
          value: "Agosto",
        },
        {
          text: "Septiembre",
          value: "Septiembre",
        },
        {
          text: "Octubre",
          value: "Octubre",
        },
        {
          text: "Noviembre",
          value: "Noviembre",
        },
        {
          text: "Diciembre",
          value: "Diciembre",
        },
      ],
      onFilter: (value, record) => record.address.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Borrar",
      dataIndex: "Delete",
      className: "table-header",
      render: (user) => {
        return (
          <div className="column-icon">
            <DeleteOutlined
              style={{ cursor: "pointer", color: "red" }}
              className="icon"
              onClick={() => {
                setCurrentUser(user);
                setIsDeleteModalVisible(!isDeleteModalVisible);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={usersRecords} />
      <UsuariosDeleteModal
        getUsers={getUsers}
        currentUser={currentUser}
        isDeleteModalVisible={isDeleteModalVisible}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default UsuariosHeader;
