
import React from "react";

import Medicamento from "../../components/forms/Medicamento";
import MedicamentoOtro from "../../components/forms/MedicamentoOtro";

const OtrosMedicamentos = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Vericiguat"
                checked={state.vericiguatCheck}
                input={state.vericiguat}
                date={state.vericiguatDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="vericiguat"
                nameCheck="vericiguatCheck"
                nameDate="vericiguatDate"
            />
            <Medicamento
                labelName="Omencativ mercarbil"
                checked={state.omencativMercarbilCheck}
                input={state.omencativMercarbil}
                date={state.omencativMercarbilDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="omencativMercarbil"
                nameCheck="omencativMercarbilCheck"
                nameDate="omencativMercarbilDate"
            />
            <Medicamento
                labelName="Dapagliflozina"
                checked={state.dapagliflozinaCheck}
                input={state.dapagliflozina}
                date={state.dapagliflozinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dapagliflozina"
                nameCheck="dapagliflozinaCheck"
                nameDate="dapagliflozinaDate"
            />
            <Medicamento
                labelName="Empagliflozina"
                checked={state.empagliflozinaCheck}
                input={state.empagliflozina}
                date={state.empagliflozinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="empagliflozina"
                nameCheck="empagliflozinaCheck"
                nameDate="empagliflozinaDate"
            />
            <Medicamento
                labelName="Estatinas"
                checked={state.estatinasCheck}
                input={state.estatinas}
                date={state.estatinasDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="estatinas"
                nameCheck="estatinasCheck"
                nameDate="estatinasDate"
            />
            <Medicamento
                labelName="Anticoagulantes
                orales"
                checked={state.anticoagulantesOralesCheck}
                input={state.anticoagulantesOrales}
                date={state.anticoagulantesOralesDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="anticoagulantesOrales"
                nameCheck="anticoagulantesOralesCheck"
                nameDate="anticoagulantesOralesDate"
            />
            <Medicamento
                labelName="Antiagregantes
                plaquetarios"
                checked={state.antiagregantesPlaquetariosCheck}
                input={state.antiagregantesPlaquetarios}
                date={state.antiagregantesPlaquetariosDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="antiagregantesPlaquetarios"
                nameCheck="antiagregantesPlaquetariosCheck"
                nameDate="antiagregantesPlaquetariosDate"
            />
            <Medicamento
                labelName="AINES"
                checked={state.AINESCheck}
                input={state.AINES}
                date={state.AINESDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="AINES"
                nameCheck="AINESCheck"
                nameDate="AINESDate"
            />
            <MedicamentoOtro
                labelName="Otro"
                checked={state.primerOtroCheck}
                input={state.primerOtro}
                date={state.primerOtroDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="primerOtro"
                nameCheck="primerOtroCheck"
                nameDate="primerOtroDate"
                nameOtro="primerOtroNombre"
            />
            <MedicamentoOtro
                labelName="Otro"
                checked={state.segundoOtroCheck}
                input={state.segundoOtro}
                date={state.segundoOtroDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="segundoOtro"
                nameCheck="segundoOtroCheck"
                nameDate="segundoOtroDate"
                nameOtro="segundoOtroNombre"
            />
            <MedicamentoOtro
                labelName="Otro"
                checked={state.tercerOtroCheck}
                input={state.tercerOtro}
                date={state.tercerOtroDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="tercerOtro"
                nameCheck="tercerOtroCheck"
                nameDate="tercerOtroDate"
                nameOtro="tercerOtroNombre"
            />
            <MedicamentoOtro
                labelName="Otro"
                checked={state.cuartoOtroCheck}
                input={state.cuartoOtro}
                date={state.cuartoOtroDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="cuartoOtro"
                nameCheck="cuartoOtroCheck"
                nameDate="cuartoOtroDate"
                nameOtro="cuartoOtroNombre"
            />
        </>
    );
};

export default OtrosMedicamentos;