import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const BloqueadoresBeta = ({
  onChangeField,
  onChangeDate,
  onChangeCheck,
  state,
}) => {
  return (
    <>
      <MedicamentoDisabled
        labelName="Bisoprolol"
        checked={state.bisoprololAnteriorCheck}
        input={state.bisoprololAnterior}
        date={state.bisoprololAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="bisoprololAnterior"
        nameCheck="bisoprololAnteriorCheck"
        nameDate="bisoprololAnteriorDate"
        state={state}
      />
      <MedicamentoDisabled
        labelName="Carvedilol"
        checked={state.carvedilololAnteriorCheck}
        input={state.carvedilolAnterior}
        date={state.carvedilololAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="carvedilolAnterior"
        nameCheck="carvedilolAnteriorCheck"
        nameDate="carvedilolAnteriorDate"
        state={state}
      />
      <MedicamentoDisabled
        labelName="Succinato de
                metoprolol (CR/XL)"
        checked={state.succinatoDeMetoprololAnteriorCheck}
        input={state.succinatoDeMetoprololAnterior}
        date={state.succinatoDeMetoprololAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="succinatoDeMetoprololAnterior"
        nameCheck="succinatoDeMetoprololAnteriorCheck"
        nameDate="succinatoDeMetoprololAnteriorDate"
      />
      <MedicamentoDisabled
        labelName="Nebivolol"
        checked={state.nebivololAnteriorCheck}
        input={state.nebivololAnterior}
        date={state.nebivololAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="nebivololAnterior"
        nameCheck="nebivololAnteriorCheck"
        nameDate="nebivololAnteriorDate"
      />
      <MedicamentoDisabled
        labelName="Tartrato de
                metoprolol"
        checked={state.tartratoDeMetoprololAnteriorCheck}
        input={state.tartratoDeMetoprololAnterior}
        date={state.tartratoDeMetoprololAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="tartratoDeMetoprololAnterior"
        nameCheck="tartratoDeMetoprololAnteriorCheck"
        nameDate="tartratoDeMetoprololAnteriorDate"
      />
    </>
  );
};

export default BloqueadoresBeta;
