import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";

const ChartLine = ({ data, dataKeys }) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#75b2c4" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#75b2c4" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="monthName" />
        <YAxis />
        <CartesianGrid strokeDasharray="2 2" />
        <Tooltip />
        {dataKeys.y.map((key) => {
          return (
            <Area
              type="monotone"
              dataKey={key}
              key={key}
              stroke="#75b2c4"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ChartLine;
