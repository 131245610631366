import React from "react";

import { Form, Input, Radio, DatePicker } from "antd";

const GeneralData = ({
  onChangeField,
  onChangeDate,
  state,
  onChangeNacimiento,
}) => {
  return (
    <>
      <Form.Item
        label="El paciente es parte de la clinica de Insuficiencia Cardiaca"
        name="origen"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <Radio.Group onChange={onChangeField} name="origen" id="origen">
          <Radio id="origen" value="Si">
            Si
          </Radio>
          <Radio id="origen" value="No">
            No
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="ID Local"
        name="cedula"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <Input onChange={onChangeField} name="cedula" id="cedula" />
      </Form.Item>

      <Form.Item
        label="Médico referente"
        name="MedicoReferente"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <Input
          value={state.edad}
          name="MedicoReferente"
          onChange={onChangeField}
        />
      </Form.Item>

      <Form.Item
        label="Médico tratante"
        name="MedicoTratante"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <Input name="MedicoTratante" onChange={onChangeField} />
      </Form.Item>

      <Form.Item
        label="Fecha de nacimiento"
        name="nacimiento"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeNacimiento(date, dateString, "nacimiento")
          }
          name="nacimiento"
        />
      </Form.Item>

      <Form.Item
        label="Edad"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
        name="edad"
      >
        <Input disabled={true} onChange={onChangeField} name="edad" />
      </Form.Item>

      <Form.Item
        label="Sexo"
        name="sexo"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <Radio.Group onChange={onChangeField} name="sexo">
          <Radio id="sexo" value="masculino">
            Masculino
          </Radio>
          <Radio id="sexo" value="femenino">
            Femenino
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Raza"
        name="raza"
        rules={[
          {
            required: true,
            message: "Porfavor agregue el campo",
          },
        ]}
      >
        <Radio.Group onChange={onChangeField} name="raza">
          <Radio id="raza" value="caucasica">
            caucasica
          </Radio>
          <Radio id="raza" value="indigena">
            indigena
          </Radio>
          <Radio id="raza" value="hispano">
            hispano
          </Radio>
          <Radio id="raza" value="asiatico">
            asiatico
          </Radio>
          <Radio id="raza" value="afroamericano">
            afroamericano
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default GeneralData;
