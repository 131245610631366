import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.actions";
import withLocation from "../../hooks/withLocation";
import { saveToLocalStorage } from "./../../hooks/usePersistedState";

import LoginForm from "../components/LoginForm";
import "./Login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "doctor@ximet.com",
      password: "pass1234",
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {

    const {setCurrentUser} = this.props;

    if(!this.state.email || !this.state.password){
      alert("No olvides llenar todos los campos");
    }else{
      try {

        await fetch(`https://ximet.herokuapp.com/api/v1/users/login?email=${this.state.email}&password=${this.state.password}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then(async (records) => {
          const user = records.data.user;
          const payload = {
            ...user,
            isLoggedIn: true,
            loggedInAt: new Date().valueOf(),
          };
          setCurrentUser(payload);
          saveToLocalStorage("User", payload);
          window.location.href="/home"
        });
      } catch (error) {
        alert("Email o contraseña incorrectos");
      }
    }    
  }

  render() {
    const { handleChange, handleSubmit, state } = this;

    return (
      <LoginForm
      state={state}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      />
      );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    currentUser: user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default withLocation(
  connect(mapStateToProps, mapDispatchToProps)(Login)
  );
