
import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const Diureticos = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Furosemida Oral"
                checked={state.furosemidaOralAnteriorCheck}
                input={state.furosemidaOralAnterior}
                date={state.furosemidaOralAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="furosemidaOralAnterior"
                nameCheck="furosemidaOralAnteriorCheck"
                nameDate="furosemidaOralAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Furosemida IV"
                checked={state.furosemidaIVAnteriorCheck}
                input={state.furosemidaIVAnterior}
                date={state.furosemidaIVAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="furosemidaIVAnterior"
                nameCheck="furosemidaIVAnteriorCheck"
                nameDate="furosemidaIVAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Bumetanida"
                checked={state.bumetanidaAnteriorCheck}
                input={state.bumetanidaAnterior}
                date={state.bumetanidaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="bumetanidaAnterior"
                nameCheck="bumetanidaAnteriorCheck"
                nameDate="bumetanidaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Hidroclorotiazida"
                checked={state.hidroclorotiazidaAnteriorCheck}
                input={state.hidroclorotiazidaAnterior}
                date={state.hidroclorotiazidaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="hidroclorotiazidaAnterior"
                nameCheck="hidroclorotiazidaAnteriorCheck"
                nameDate="hidroclorotiazidaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Clortalidona"
                checked={state.clortalidonaAnteriorCheck}
                input={state.clortalidonaAnterior}
                date={state.clortalidonaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="clortalidonaAnterior"
                nameCheck="clortalidonaAnteriorCheck"
                nameDate="clortalidonaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="lndapamida"
                checked={state.indapamidaAnteriorCheck}
                input={state.indapamidaAnterior}
                date={state.indapamidaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="indapamidaAnterior"
                nameCheck="indapamidaAnteriorCheck"
                nameDate="indapamidaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Espironolactona/
                Eplerrenona"
                checked={state.espironolactonaEplerrenonaAnteriorCheck}
                input={state.espironolactonaEplerrenonaAnterior}
                date={state.espironolactonaEplerrenonaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="espironolactonaEplerrenonaAnterior"
                nameCheck="espironolactonaEplerrenonaAnteriorCheck"
                nameDate="espironolactonaEplerrenonaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Amilorida"
                checked={state.amiloridaAnteriorCheck}
                input={state.amiloridaAnterior}
                date={state.amiloridaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="amiloridaAnterior"
                nameCheck="amiloridaAnteriorCheck"
                nameDate="amiloridaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Triamtereno"
                checked={state.triamterenoAnteriorCheck}
                input={state.triamterenoAnterior}
                date={state.triamterenoAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="triamterenoAnterior"
                nameCheck="triamterenoAnteriorCheck"
                nameDate="triamterenoAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Digoxina"
                checked={state.digoxinaAnteriorCheck}
                input={state.digoxinaAnterior}
                date={state.digoxinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="digoxinaAnterior"
                nameCheck="digoxinaAnteriorCheck"
                nameDate="digoxinaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Hidralazina"
                checked={state.hidralazinaAnteriorCheck}
                input={state.hidralazinaAnterior}
                date={state.hidralazinaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="hidralazinaAnterior"
                nameCheck="hidralazinaAnteriorCheck"
                nameDate="hidralazinaAnteriorDate"
            />
        </>
    );
};

export default Diureticos;