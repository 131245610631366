import React from "react";

import { Input, DatePicker, Checkbox, Form } from "antd";

const MedicamentoOtroDisabled = ({
  labelName,
  checked,
  onChangeCheck,
  nameCheck,
  input,
  onChangeField,
  name,
  date,
  onChangeDate,
  nameDate,
  nameOtro,
}) => {
  return (
    <>
      <Input.Group compact>
        <div style={{ width: "20%" }}>{labelName}</div>
        <Form.Item style={{ width: "5%" }} name={nameCheck}>
          <Checkbox
            onChange={onChangeCheck}
            name={nameCheck}
            checked={checked}
            disabled={true}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} name={nameOtro}>
          <Input onChange={onChangeField} name={nameOtro} disabled={true} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} name={name}>
          <Input
            placeholder="dosis mg"
            onChange={onChangeField}
            name={name}
            disabled={true}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} name={nameDate}>
          <DatePicker
            format="DD-MM-YYYY"
            onChange={(date, dateString) =>
              onChangeDate(date, dateString, nameDate)
            }
            disabled={true}
          />
        </Form.Item>
      </Input.Group>
    </>
  );
};

export default MedicamentoOtroDisabled;
