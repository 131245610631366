import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Legend,
  Bar,
  ResponsiveContainer
} from "recharts";
function ChartBar({ data, dataKeys }) {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart width={1000} height={350} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeys.x}/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={dataKeys.y} fill="#75b2c4" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChartBar;
