import React from "react";

import { Input, DatePicker, Checkbox, Form } from "antd";

const Medicamento = ({
  labelName,
  checked,
  onChangeCheck,
  nameCheck,
  input,
  onChangeField,
  name,
  date,
  onChangeDate,
  nameDate,
}) => {
  return (
    <Input.Group compact>
      <div style={{ width: "20%" }}>{labelName}</div>
      <Form.Item style={{ width: "5%" }}>
        <Checkbox checked={checked} onChange={onChangeCheck} name={nameCheck} />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }} name={name}>
        <Input
          placeholder="dosis mg"
          onChange={onChangeField}
          name={name}
          disabled={!checked}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }} name={nameDate}>
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, nameDate)
          }
          disabled={!checked}
        />
      </Form.Item>
    </Input.Group>
  );
};

export default Medicamento;
