import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const ARM = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Eplerenona"
                checked={state.eplerenonaAnteriorCheck}
                input={state.eplerenonaAnterior}
                date={state.eplerenonaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="eplerenonaAnterior"
                nameCheck="eplerenonaAnteriorCheck"
                nameDate="eplerenonaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Espironolactona"
                checked={state.espironolactonaAnteriorCheck}
                input={state.espironolactonaAnterior}
                date={state.espironolactonaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="espironolactonaAnterior"
                nameCheck="espironolactonaAnteriorCheck"
                nameDate="espironolactonaAnteriorDate"
            />
        </>
    );
};

export default ARM;