import React from "react";
import { Navigate, useLocation } from "react-router";
import useAuth from "../hooks/useAuth";

const NavigateHome = ({ children }) => {
  const isAuth = useAuth();
  const location = useLocation();
  if (isAuth) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }
  return children;
};

export default NavigateHome;
