import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const IECA = ({ onChangeField, onChangeDate, onChangeCheck, state }) => {
  return (
    <>
      <MedicamentoDisabled
        labelName="Captopril"
        checked={state.captoprilAnteriorCheck}
        input={state.captoprilAnterior}
        date={state.captoprilAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="captoprilAnterior"
        nameCheck="captoprilAnteriorCheck"
        nameDate="captoprilAnteriorDate"
        state={state}
      />
      <MedicamentoDisabled
        labelName="Enalapril"
        checked={state.enalaprilAnteriorCheck}
        input={state.enalaprilAnterior}
        date={state.enalaprilAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="enalaprilAnterior"
        nameCheck="enalaprilAnteriorCheck"
        nameDate="enalaprilAnteriorDate"
      />
      <MedicamentoDisabled
        labelName="Lisinopril"
        checked={state.lisinoprilAnteriorCheck}
        input={state.lisinoprilAnterior}
        date={state.lisinoprilAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="lisinoprilAnterior"
        nameCheck="lisinoprilAnteriorCheck"
        nameDate="lisinoprilAnteriorDate"
      />
      <MedicamentoDisabled
        labelName="Ramipril"
        checked={state.ramiprilAnteriorCheck}
        input={state.ramiprilAnterior}
        date={state.ramiprilAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="ramiprilAnterior"
        nameCheck="ramiprilAnteriorCheck"
        nameDate="ramiprilAnteriorDate"
      />
      <MedicamentoDisabled
        labelName="Trandolaprila"
        checked={state.trandolaprilaAnteriorCheck}
        input={state.trandolaprilaAnterior}
        date={state.trandolaprilaAnteriorDate}
        onChangeField={onChangeField}
        onChangeDate={onChangeDate}
        onChangeCheck={onChangeCheck}
        name="trandolaprilaAnterior"
        nameCheck="trandolaprilaAnteriorCheck"
        nameDate="trandolaprilaAnteriorDate"
      />
    </>
  );
};

export default IECA;
