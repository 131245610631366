import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const ARM = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Eplerenona"
                checked={state.eplerenonaCheck}
                input={state.eplerenona}
                date={state.eplerenonaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="eplerenona"
                nameCheck="eplerenonaCheck"
                nameDate="eplerenonaDate"
            />
            <Medicamento
                labelName="Espironolactona"
                checked={state.espironolactonaCheck}
                input={state.espironolactona}
                date={state.espironolactonaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="espironolactona"
                nameCheck="espironolactonaCheck"
                nameDate="espironolactonaDate"
            />
        </>
    );
};

export default ARM;