import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import MainLayout from "./shared/components/layout/MainLayout";
import Basal from "./basal/pages/Basal";
import Consulta from "./consulta/pages/Consulta";
import Home from "./home/pages/Home";
import Usuarios from "./usuarios/pages/Usuarios";
import Login from "./login/page/Login";
import { setCurrentUser } from "./redux/user/user.actions";
import Hospitalizacion from "./hospitalizacion/pages/Hospitalizacion";
import Urgencias from "./urgencias/pages/Urgencias";
import Desenlace from "./desenlace/pages/Desenlace";
import Context from "./hooks/Context";
import PrivateRoute from "./routes/privateRoute.component";
import NavigateHome from "./routes/NavigateHome";
import { getFromLocalStorage } from "./hooks/usePersistedState";
import Dashboard from "./dashboard/pages/Dashboard";

class App extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { setCurrentUser } = this.props;
    const user = getFromLocalStorage("User");
    if (
      user &&
      user.isLoggedIn &&
      user.loggedInAt + 24 * 60 * 60 * 1000 > user.loggedInAt
    ) {
      setCurrentUser(user);
    }
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Context>
            <Routes>
              <Route
                path="/login"
                exact
                element={
                  <NavigateHome>
                    <Login />
                  </NavigateHome>
                }
              />

              <Route element={<PrivateRoute />}>
                <Route element={<MainLayout />}>
                  <Route path="/home" exact element={<Home />} />
                  <Route path="/" exact element={<Home />} />
                  <Route path="/dashboard" exact element={<Dashboard />} />
                  <Route path="/usuarios" exact element={<Usuarios />} />
                  <Route path="/basal/:id_paciente" exact element={<Basal />} />
                  <Route
                    path="/consulta/:id_paciente/:id"
                    exact
                    element={<Consulta />}
                  />
                  <Route
                    path="/hospitalizacion/:id_paciente/:id"
                    exact
                    element={<Hospitalizacion />}
                  />
                  <Route
                    path="/urgencia/:id_paciente/:id"
                    exact
                    element={<Urgencias />}
                  />
                  <Route
                    path="/desenlace/:id_paciente"
                    exact
                    element={<Desenlace />}
                  />
                </Route>
              </Route>
              <Route
                path="*"
                element={<div>La pagina no existe error 404</div>}
              />
            </Routes>
          </Context>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
