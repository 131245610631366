import React from "react";
import { Form, Input, Radio, Checkbox } from "antd";

import { ruleNumber } from "../../utils/rules";

const ExploracionFisica = ({
  onChangeField,
  onChangeCheck,
  onChangeNumber,
  state,
}) => {
  return (
    <>
      <Form.Item
        label="Frecuencia cardíaca (lpm)"
        name="frecuenciaCardiaca"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="frecuenciaCardiaca" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="TA Sistólica (mmHg)"
        name="TASistolica"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="TASistolica" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="TA Diastólica (mmHg)"
        name="TADiastolica"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="TADiastolica" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Peso (Kg)"
        name="peso"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="peso" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="Talla (cm)"
        name="talla"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="talla" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      <Form.Item
        label="IMC (Calculado)"
        name="IMC"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input disabled={true} name="IMC" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>
      {/** 
      <Form.Item
        label="Superficie corporal"
        name="superficieCorporal"
        rules={ruleNumber}
        validateTrigger="onChange"
        wrapperCol={{ span: 2 }}
      >
        <Input name="superficieCorporal" onChange={(e) => onChangeNumber(e)} />
      </Form.Item>*/}

      <Form.Item label="Plétora yugular" name="pletoraYugular">
        <Radio.Group onChange={onChangeField} name="pletoraYugular">
          <Radio value="primerGrado">Primer grado</Radio>
          <Radio value="segundoGrado">Segundo grado</Radio>
          <Radio value="tercerGrado">Tercer grado</Radio>
        </Radio.Group>
      </Form.Item>
      <div className="checkBoxContainer">
        <div>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.reflujoHepatoyugularCheck}
              onChange={onChangeCheck}
              name="reflujoHepatoyugularCheck"
            >
              Reflujo hepatoyugular
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.tercerSonidoCardiacoCheck}
              onChange={onChangeCheck}
              name="tercerSonidoCardiacoCheck"
            >
              Tercer sonido cardiaco (ritmo galopante)
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.impulsoApicalDesplazadoLateralmenteCheck}
              onChange={onChangeCheck}
              name="impulsoApicalDesplazadoLateralmenteCheck"
            >
              Impulso apical desplazado lateralmente
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Checkbox
              checked={state.cuartoRuidoCheck}
              onChange={onChangeCheck}
              name="cuartoRuidoCheck"
            >
              4to ruido
            </Checkbox>
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Checkbox
              checked={state.estertoresCrepitantesCheck}
              onChange={onChangeCheck}
              name="estertoresCrepitantesCheck"
            >
              Estertores crepitantes
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={state.hepatomegaliaCheck}
              onChange={onChangeCheck}
              name="hepatomegaliaCheck"
            >
              Hepatomegalia
            </Checkbox>
          </Form.Item>
          <Input.Group compact>
            <Form.Item>
              <Checkbox
                checked={state.otroExploracionFisicaCheck}
                onChange={onChangeCheck}
                name="otroExploracionFisicaCheck"
              >
                Otro
              </Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }} name="otroExploracionFisica">
              <Input
                disabled={!state.otroExploracionFisicaCheck}
                onChange={onChangeField}
                name="otroExploracionFisica"
              />
            </Form.Item>
          </Input.Group>
        </div>
      </div>
    </>
  );
};

export default ExploracionFisica;
