import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const ARAII = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Candesartán"
                checked={state.candersartanCheck}
                input={state.candersartan}
                date={state.candersartanDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="candersartan"
                nameCheck="candersartanCheck"
                nameDate="candersartanDate"
            />
            <Medicamento
                labelName="Valsartán"
                checked={state.valsartanCheck}
                input={state.valsartan}
                date={state.valsartanDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="valsartan"
                nameCheck="valsartanCheck"
                nameDate="valsartanDate"
            />
            <Medicamento
                labelName="Losartán"
                checked={state.losartanCheck}
                input={state.losartan}
                date={state.losartanDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="losartan"
                nameCheck="losartanCheck"
                nameDate="losartanDate"
            />
            <Medicamento
                labelName="Telmisartán"
                checked={state.telmisartanCheck}
                input={state.telmisartan}
                date={state.telmisartanDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="telmisartan"
                nameCheck="telmisartanCheck"
                nameDate="telmisartanDate"
            />
        </>
    );
};

export default ARAII;