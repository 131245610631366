
import React from "react";

import MedicamentoDisabled from "../../components/forms/MedicamentoDisabled";

const AgentesInotropicos = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <MedicamentoDisabled
                labelName="Dobutamina"
                checked={state.dobutaminaAnteriorCheck}
                input={state.dobutaminaAnterior}
                date={state.dobutaminaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dobutaminaAnterior"
                nameCheck="dobutaminaAnteriorCheck"
                nameDate="dobutaminaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Dopamina"
                checked={state.dopaminaAnteriorCheck}
                input={state.dopaminaAnterior}
                date={state.dopaminaAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dopaminaAnterior"
                nameCheck="dopaminaAnteriorCheck"
                nameDate="dopaminaAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Levosimendán"
                checked={state.levosimendanAnteriorCheck}
                input={state.levosimendanAnterior}
                date={state.levosimendanAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="levosimendanAnterior"
                nameCheck="levosimendanAnteriorCheck"
                nameDate="levosimendanAnteriorDate"
            />
            <MedicamentoDisabled
                labelName="Inhibidores de la
                fosfodiesterasa III"
                checked={state.inhibidoresDeLaFosfodiesterasaIIIAnteriorCheck}
                input={state.inhibidoresDeLaFosfodiesterasaIIIAnterior}
                date={state.inhibidoresDeLaFosfodiesterasaIIIAnteriorDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="inhibidoresDeLaFosfodiesterasaIIIAnterior"
                nameCheck="inhibidoresDeLaFosfodiesterasaIIIAnteriorCheck"
                nameDate="inhibidoresDeLaFosfodiesterasaIIIAnteriorDate"
            />
        </>
    );
};

export default AgentesInotropicos;