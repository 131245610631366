import React from "react";
import { connect } from "react-redux";

import HomeTable from "../components/HomeTable";
import HomeHeader from "../components/HomeHeader";
import { setCurrentPacientes } from "./../../redux/paciente/paciente.actions";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      patientsRecords: [],
      patients: 0,
    };
  }

  getPatients = async () => {
    this.setState({ patientsRecords: [] });
    let i = 0;

    await fetch("https://ximet.herokuapp.com/api/v1/patient", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((records) => {
        const patients = records.data.patients.map((record) => {
          i = i + 1;
          return {
            key: record.id,
            ID: record.id,
            PacienteID: record.id_paciente,
            createdAt: record.createdAt,
            basal: record.id_paciente,
            Consulta: {
              records: record.consultas ? record.consultas : [],
              id_paciente: record.id_paciente,
            },
            Hospitalizacion: {
              records: record.hospitalizaciones ? record.hospitalizaciones : [],
              id_paciente: record.id_paciente,
            },
            Urgencia: {
              records: record.urgencias ? record.urgencias : [],
              id_paciente: record.id_paciente,
            },
            desenlace: record.id_paciente,
            fecha: record.createdAt,
            borrar: record.id_paciente,
          };
        });
        this.setState(() => ({
          patientsRecords: patients,
          patients: i,
        }));
        this.props.setCurrentPacientes(patients);
      });
  };

  componentDidMount = async () => {
    this.getPatients();
  };

  addNewPatient = async () => {
    const response = await fetch("https://ximet.herokuapp.com/api/v1/patient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => response);

    if (response.status === "success") {
      this.getPatients();
    } else {
    }
  };

  render() {
    const { addNewPatient } = this;

    return (
      <>
        <HomeHeader addNewPatient={addNewPatient} />
        <HomeTable
          key={this.state.patients}
          addNewPatient={addNewPatient}
          patientsRecords={this.state.patientsRecords}
          getPatients={this.getPatients}
        />
      </>
    );
  }
}

const mapStateToProps = ({ pacientes }) => {
  return {
    pacientes: [pacientes],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPacientes: (pacientes) =>
      dispatch(setCurrentPacientes(pacientes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
