
import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const IECA = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    
    return(
        <>
            <Medicamento
                labelName="Captopril"
                checked={state.captoprilCheck}
                input={state.captopril}
                date={state.captoprilDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="captopril"
                nameCheck="captoprilCheck"
                nameDate="captoprilDate"
            />
            <Medicamento
                labelName="Enalapril"
                checked={state.enalaprilCheck}
                input={state.enalapril}
                date={state.enalaprilDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="enalapril"
                nameCheck="enalaprilCheck"
                nameDate="enalaprilDate"
            />
            <Medicamento
                labelName="Lisinopril"
                checked={state.lisinoprilCheck}
                input={state.lisinopril}
                date={state.lisinoprilDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="lisinopril"
                nameCheck="lisinoprilCheck"
                nameDate="lisinoprilDate"
            />
            <Medicamento
                labelName="Ramipril"
                checked={state.ramiprilCheck}
                input={state.ramipril}
                date={state.ramiprilDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="ramipril"
                nameCheck="ramiprilCheck"
                nameDate="ramiprilDate"
            />
            <Medicamento
                labelName="Trandolaprila"
                checked={state.trandolaprilaCheck}
                input={state.trandolaprila}
                date={state.trandolaprilaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="trandolaprila"
                nameCheck="trandolaprilaCheck"
                nameDate="trandolaprilaDate"
            />
        </>
    );
};

export default IECA;