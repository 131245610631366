export const floatRegularExpression3Max = new RegExp(/\d+\.?/, "g");

export const ruleNumber = [
  {
    pattern: /[0-9]/g,
    message: "Valor solamente debe contener numeros",
  },
];

export async function changeForm(context, newState = {}) {
  await context.setState({ ...context.state, ...newState });
  context.ref.current.setFieldsValue({
    ...context.state,
    ...newState,
  });
}

export function changeNewStateObject(newState, newEntrie = {}) {
  return { ...newState, ...newEntrie };
}
