import React from "react";

import { Form, Radio } from "antd";

const SocioEconomico = ({ onChangeField }) => {
  return (
    <>
      <Form.Item label="Escolaridad" name="escolaridad">
        <Radio.Group onChange={onChangeField} name="escolaridad">
          <Radio value="primaria">Primaria</Radio>
          <Radio value="secundaria">Secundaria</Radio>
          <Radio value="Educacion superior (otra)">
            Educación superior (otra)
          </Radio>
          <Radio value="licenciatura">Licenciatura</Radio>
          <Radio value="ninguna">Ninguna</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Red de apoyo" name="redDeApoyo">
        <Radio.Group name="redDeApoyo" onChange={onChangeField}>
          <Radio value="habitaSolo">Habita Solo</Radio>
          <Radio value="habitaConFamilia">Habita con familia</Radio>
          <Radio value="habitaEnCasaDeAsistencia">
            Habita en casa de asistencia/asilo
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Soporte de Apoyo" name="soporteDeApoyo">
        <Radio.Group onChange={onChangeField} name="soporteDeApoyo">
          <Radio value="perteneceAUnGrupoDeApoyo">
            Pertenece a un grupo de apoyo
          </Radio>
          <Radio value="noPerteneceAUnGrupoDeApoyo">
            No pertenece a ningún grupo de apoyo
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="¿Paciente cuenta con Seguro de Gastos Médicos?"
        name="gastosMedicos"
      >
        <Radio.Group onChange={onChangeField} name="gastosMedicos">
          <Radio value="noAplica">No aplica</Radio>
          <Radio value="si">Si</Radio>
          <Radio value="privado">Privado</Radio>
          <Radio value="publico">Publlico</Radio>
          <Radio value="otro">Otro</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Vivieda" name="vivienda">
        <Radio.Group onChange={onChangeField} name="vivienda">
          <Radio value="urbana">Urbana</Radio>
          <Radio value="rural">Rural</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Aporta económicamente" name="aportaEconomicamente">
        <Radio.Group onChange={onChangeField} name="aportaEconomicamente">
          <Radio value="si">Si</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Rehabilitación" name="rehabilitacion">
        <Radio.Group onChange={onChangeField} name="rehabilitacion">
          <Radio value="si"> Si</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Cuidados paliativos" name="cuidadosPaliativos">
        <Radio.Group onChange={onChangeField} name="cuidadosPaliativos">
          <Radio value="si">Si</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default SocioEconomico;
