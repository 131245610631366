import React from "react";

import { Collapse, } from 'antd';
import IECA from '../components/IECA';
import BloqueadoresBeta from '../components/BloqueadoresBeta';
import ARAII from '../components/ARAII';
import ARM from '../components/ARM';
import INRA from '../components/INRA';
import BloqueadoresDelCanalIF from '../components/BloqueadoresDelCanalIF';
import Diureticos from '../components/Diureticos';
import Vasodilatadores from '../components/Vasodilatadores';
import AgentesInotropicos from '../components/AgentesInotropicos';
import OtrosMedicamentos from '../components/OtrosMedicamentos';

const { Panel } = Collapse;

const MedicamentosDisabled = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <Collapse bordered={false} defaultActiveKey={['1']} >
            
            <Panel header="IECA" key="1">
                <IECA className="medicamentos" 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="Bloqueadores beta" key="2">
                <BloqueadoresBeta 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state}
                 />
            </Panel>
            <Panel header="ARA-II" key="3">
                <ARAII 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="ARM" key="4">
                <ARM 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="INRA" key="5">
                <INRA 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="Bloqueadores del canal If" key="6">
                <BloqueadoresDelCanalIF 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="Diuréticos" key="7">
                <Diureticos 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="Vasodilatadores" key="8">
                <Vasodilatadores 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="Agentes inotrópicos" key="9">
                <AgentesInotropicos 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            <Panel header="Otros medicamentos" key="10">
                <OtrosMedicamentos 
                onChangeCheck={onChangeCheck} 
                onChangeField={onChangeField} 
                onChangeDate={onChangeDate} 
                state={state} />
            </Panel>
            
        </Collapse>
    );
};

export default MedicamentosDisabled;