
import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const BloqueadoresBeta = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Bisoprolol"
                checked={state.bisoprololCheck}
                input={state.bisoprolol}
                date={state.bisoprololDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="bisoprolol"
                nameCheck="bisoprololCheck"
                nameDate="bisoprololDate"
            />
            <Medicamento
                labelName="Carvedilol"
                checked={state.carvedilolCheck}
                input={state.carvedilol}
                date={state.carvedilolDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="carvedilol"
                nameCheck="carvedilolCheck"
                nameDate="carvedilolDate"
            />
            <Medicamento
                labelName="Succinato de
                metoprolol (CR/XL)"
                checked={state.succinatoDeMetoprololCheck}
                input={state.succinatoDeMetoprolol}
                date={state.succinatoDeMetoprololDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="succinatoDeMetoprolol"
                nameCheck="succinatoDeMetoprololCheck"
                nameDate="succinatoDeMetoprololDate"
            />
            <Medicamento
                labelName="Nebivolol"
                checked={state.nebivololCheck}
                input={state.nebivolol}
                date={state.nebivololDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nebivolol"
                nameCheck="nebivololCheck"
                nameDate="nebivololDate"
            />
            <Medicamento
                labelName="Tartrato de
                metoprolol"
                checked={state.tartratoDeMetoprololCheck}
                input={state.tartratoDeMetoprolol}
                date={state.tartratoDeMetoprololDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="tartratoDeMetoprolol"
                nameCheck="tartratoDeMetoprololCheck"
                nameDate="tartratoDeMetoprololDate"
            />
        </>
    );
};

export default BloqueadoresBeta;