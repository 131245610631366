import React from "react";
import moment from "moment";
import { Collapse, Form, Modal } from "antd";

import "./Consulta.css";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import Header from "../../shared/components/layout/Header";
import GeneralDataConsulta from "../components/GeneralDataConsulta";
import Antecedentes from "../components/Antecedentes";
import ExploracionFisica from "../../shared/ExploracionFisica/ExploracionFisica";
import MedicamentosDisabled from "../../shared/medicamentoDisabled/pages/Medicamentos";
import EstudioDeGabinete from "../../shared/EstudiosDeGabinete/EstudiosDeGabinete";
import EstudiosDeLaboratorio from "../../shared/EstudiosDeLaboratorio/EstudiosDeLaboratorios";
import Medicamentos from "../../shared/medicamento/pages/Medicamentos";
import Cuestionario from "../components/Cuestionario";
import SiguienteConsulta from "../components/SiguienteConsulta";
import withParams from "../../hooks/withParams";
import { onChangeNum } from "../../utils/onChangeNum";

const { Panel } = Collapse;

class Consulta extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.form = "appointment";
    this.state = {};
  }

  componentDidMount = async () => {
    const { id_paciente, id } = this.props.params;
    let consulta = await fetch(
      `https://ximet.herokuapp.com/api/v1/appointment/${id_paciente}/${id}`
    )
      .then((res) => res.json())
      .then((response) => response.data)
      .catch((err) => console.log(err));

    console.log(consulta);

    consulta.nacimiento = moment(consulta.nacimiento);

    Object.keys(consulta.PastMeds).forEach((key) => {
      if (
        (key.includes("Date") ||
          key.includes("date") ||
          key.includes("fecha") ||
          key.includes("nacimiento")) &&
        consulta.PastMeds[key] !== null
      ) {
        consulta.PastMeds[key] = moment(consulta.PastMeds[key]);
      }
    });

    Object.keys(consulta.Appointment).forEach((key) => {
      if (
        (key.includes("Date") ||
          key.includes("date") ||
          key.includes("fecha") ||
          key.includes("nacimiento") ||
          key.includes("SiguienteConsulta")) &&
        consulta.Appointment[key] !== null
      ) {
        consulta.Appointment[key] = moment(consulta.Appointment[key]);
      }
    });

    this.ref.current.setFieldsValue({
      ...consulta.PastMeds,
      ...consulta.Appointment,
      nacimiento: consulta.nacimiento,
    });

    console.log(consulta.PastMeds);

    this.setState({
      ...consulta.PastMeds,
      ...consulta.Appointment,
      nacimiento: consulta.nacimiento,
    });
  };

  onChangeField = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeDate = (date, dateString, id) => {
    this.setState({
      [id]: moment(dateString),
    });
  };

  onChangeCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onChangeNumber = (e) => {
    onChangeNum(this, e);
  };

  onChangeSum = () => {
    //SocioEconomica
    let sumDimensionSocioeconomica =
      this.state
        .causarleDificultadParaRelacionarseOHacerCosasConSuFamiliaOAmigos +
      this.state.causarleDificultadParaTrabajarYGanarUnSueldo +
      this.state.causarleDificultadConSusPasatiemposDeportesOHobbies +
      this.state.causarleDificultadELasRelacionesSexuales +
      this.state.hacerQueTengaQueComerMenosDeLasComidasQueAUstedLeGustan +
      this.state.hacerQueTengaQueEstarInternadoEnUnHospitalOSanatorio +
      this.state.producirleGastosEnLaSalud +
      this.state.causarleEfectosColateralesIndeseablesACausaDeLaMedicacion;

    let sumResultadoFisico =
      this.state.causarleHinchazonEnLosTobillosOPiernas +
      this.state.haverQueTengaQueSentarseORecostarseParaDescansarDuranteElDia +
      this.state.causarleDificultadParaCaminarOSubirEscaleras +
      this.state.causarleDificultadParaRealizarTrabajosEnLaCasaOElJardin +
      this.state.causarleDificultadParaIrALugaresAlejadosDeSuCasa +
      this.state.causarleDificultadParaDormirBienDeNoche +
      this.state.causarleFaltaDeAire +
      this.state.hacerQueSeSientaCansadoFatigadoOConPocaEnergia;

    let sumResultadoEmocional =
      this.state.hacerleQueSeSientaUnaCargaParaSuFamiliaOAmigos +
      this.state.hacerleSentirQueTieneUnMenosControlSobreSuVida +
      this.state.causarlePreocupacion +
      this.state.causarleDificultadoParaConcentrarseORecordarCosas +
      this.state.hacerQueSeSientaDeprimido;

    let sum =
      sumResultadoEmocional + sumResultadoFisico + sumDimensionSocioeconomica;

    this.setState({
      sumatoriaDimensionSocioeconomica: sumDimensionSocioeconomica,
      sumatoriaResultadoFisico: sumResultadoFisico,
      sumatoriaResultadoEmocional: sumResultadoEmocional,
      totalCuestionario: sum,
    });
  };

  onFinish = async () => {
    const { id_paciente, id } = this.props.params;
    const response = await fetch(
      `https://ximet.herokuapp.com/api/v1/appointment/${id_paciente}/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(this.state),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        res.json();
      })
      .then((json) => json)
      .catch((err) => console.log(err));

    Modal.success({
      title: "Has guardado correctamente!",
    });
  };

  showWarningModal = () => {
    const clearAll = () => {
      let newState = {};
      Object.keys(this.state).forEach((key) => {
        newState[key] = null;
        if (key === "id_paciente" || key === "id" || key === "nacimiento") {
          newState[key] = this.state[key];
        }
      });
      this.setState(newState, () => console.log(this.state));
      this.ref.current.resetFields();
      this.ref.current.setFieldsValue({
        id_paciente: this.props.params.id_paciente,
        id: this.state.id,
        nacimiento: this.state.nacimiento,
      });
    };

    Modal.confirm({
      title: "Espera!",
      icon: <ExclamationCircleOutlined />,
      content: `Seguro que quieres borrar todos los campos?`,
      okText: "Borrar",
      cancelText: "Cancelar",
      onOk() {
        return clearAll();
      },
      onCancel() {
        return;
      },
    });
  };

  render() {
    const {
      onChangeField,
      onChangeDate,
      onChangeCheck,
      onChangeNumber,
      onChangeSum,
      onFinish,
      showWarningModal,
      state,
    } = this;
    return (
      <div>
        <Header
          state={state}
          formType={this.form}
          id_paciente={this.props.params.id_paciente}
          formID={this.props.params.id}
          clearAll={showWarningModal}
        />
        <Form
          ref={this.ref}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          onFinish={onFinish}
        >
          <GeneralDataConsulta
            onChangeField={onChangeField}
            onChangeDate={onChangeDate}
            onChangeCheck={onChangeCheck}
            onChangeNumber={onChangeNumber}
            state={state}
          />
          <Collapse bordered={false} defaultActiveKey={["7"]}>
            <Panel forceRender={false} header="Antecedentes" key="1">
              <Antecedentes
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={false} header="Exploración Fisica" key="2">
              <ExploracionFisica
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={false} header="Medicamentos Anteriores" key="3">
              <MedicamentosDisabled
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel
              forceRender={false}
              header="Últimos estudios de gabinete"
              key="4"
            >
              <EstudioDeGabinete
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel
              forceRender={false}
              header="Últimos estudios de laboratorio"
              key="5"
            >
              <EstudiosDeLaboratorio
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel forceRender={false} header="Medicamentos actuales" key="6">
              <Medicamentos
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
            <Panel
              forceRender={false}
              header="Cuestionario Calidad de Vida"
              key="7"
            >
              <Cuestionario
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
                onChangeSum={onChangeSum}
              />
            </Panel>
            <Panel forceRender={false} header="Siguiente Consulta" key="8">
              <SiguienteConsulta
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                onChangeNumber={onChangeNumber}
                state={state}
              />
            </Panel>
          </Collapse>
          <button id={this.form} type="submit" hidden={true}>
            SUBMIT
          </button>
        </Form>
      </div>
    );
  }
}

export default withParams(Consulta);
