import React from "react";

import { Form, Input, DatePicker } from "antd";

function GeneralDataUrgencias({ onChangeField, form, state }) {
  return (
    <div>
      <Form.Item label="Número de urgencia" name="numeroDeUrgencias">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="ID" name="cuatroUltimosDigitosDeLaCedula">
        <Input name="cuatroUltimosDigitosDeLaCedula" disabled={true} />
      </Form.Item>
      <Form.Item label="Fecha de nacimiento" name="nacimiento">
        <DatePicker format="DD-MM-YYYY" name="nacimiento" disabled={true} />
      </Form.Item>
    </div>
  );
}

export default GeneralDataUrgencias;
