import React from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import { Layout, Menu, Affix } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

import { setCurrentUser } from "../../../redux/user/user.actions";
import { removeFromLocalStorage } from "./../../../hooks/usePersistedState";
import Sidebar from "./Sidebar";

import "./MainLayout.css";

const { Header, Content } = Layout;

const MainLayout = (props) => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  console.log(user);
  return (
    <Layout>
      <Affix offsetTop={0}>
        {location !== "/home" &&
        location !== "/dashboard" &&
        location !== "/usuarios" ? (
          <div></div>
        ) : (
          <Header className="header">
            <div className="logo" />
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[location]}
            >
              <Menu.Item key="/home">
                <Link to="/home" />
                Home
              </Menu.Item>
              <Menu.Item key="/dashboard">
                <Link to="/dashboard" />
                Dashboard
              </Menu.Item>
              {user.role === "doctor" && (
                <Menu.Item key="/usuarios">
                  <Link to="/usuarios" />
                  Usuarios
                </Menu.Item>
              )}
              <Menu.Item
                key="/login"
                onClick={() => {
                  dispatch(setCurrentUser({ isLoggedIn: false }));
                  removeFromLocalStorage("User");
                }}
              >
                <Link to="/login" />
                Sign Out
              </Menu.Item>
            </Menu>
            <div className="user-detail">
              <UserOutlined/>
              <div>
                <h4>{ user.name }</h4>
                <h5>{ user.role }</h5>
              </div>
            </div>
          </Header>
        )}
      </Affix>
      <Layout>
        <Sidebar />
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {/*children*/}
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
