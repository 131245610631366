import React from "react";

import { Modal, Button, Table } from "antd";
import { Link } from "react-router-dom";
import { ContainerOutlined } from "@ant-design/icons";

import "./HomeModal.css";

function HomeModal({
  isModalVisible,
  handleOk,
  handleCancel,
  currentFormType,
  currentPatient,
  dataSource,
  getPatients,
}) {
  const { id_paciente } = currentPatient;
  const columns = [
    {
      dataIndex: "ID",
      sorter: (a, b) => a.ID - b.ID,
      className: "table-header",
    },
    {
      title: `${currentFormType}`,
      dataIndex: `${currentFormType}`,
      className: "table-header",
      render: (props) => {
        console.log(props);
        if (props === undefined) return;
        return (
          <div className="column-icon">
            <Link
              to={`/${currentFormType.toLowerCase()}/${id_paciente}/${
                props.id
              }`}
            >
              <ContainerOutlined className="icon" />
            </Link>
          </div>
        );
      },
    },
    {
      title: `Fecha`,
      dataIndex: `fecha`,
      className: "table-header",
    },
  ];

  const newRecord = async () => {
    let url;
    if (currentFormType.toLowerCase() === "urgencia") {
      url = `https://ximet.herokuapp.com/api/v1/urgency/${id_paciente}`;
    } else if (currentFormType.toLowerCase() === "consulta") {
      url = `https://ximet.herokuapp.com/api/v1/appointment/${id_paciente}`;
    } else if (currentFormType.toLowerCase() === "hospitalizacion") {
      url = `https://ximet.herokuapp.com/api/v1/hospitalization/${id_paciente}`;
    }
    let record = await fetch(url, { method: "POST" })
      .then((res) => res.json())
      .then((json) => json);

    if (record.status === "success") {
      await getPatients();
    }
  };

  if (dataSource.length === 0) {
    dataSource = [];
  }
  return (
    <Modal
      title="Paciente"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Table columns={columns} dataSource={dataSource} />
      <Button onClick={newRecord}>Nueva {currentFormType}</Button>
    </Modal>
  );
}

export default HomeModal;
