import React from "react";

import { Form, DatePicker, Radio, Input } from "antd";
import "./IntervencionesDuranteLaHospitalizacion.css";

function IntervencionesDuranteLaHospitalizacion({
  onChangeDate,
  onChangeField,
}) {
  return (
    <div>
      <span>
        <b>Tiene indicación para implante de dispositivo</b>
      </span>
      <Form.Item
        label="Fecha de implante"
        name="fechaDeImplanteHospitalizacion"
      >
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "fechaDeImplanteHospitalizacion")
          }
        />
      </Form.Item>
      <Form.Item label="|" name="fechaDeImplanteHospitalizacionRadio">
        <Radio.Group
          name="fechaDeImplanteHospitalizacionRadio"
          onChange={onChangeField}
        >
          <Radio value="desfibriladorImplantable">
            Desfibrilador Implantable
          </Radio>
          <Radio value="CRTD">CRT- D</Radio>
          <Radio value="CRTP">CRT-P</Radio>
          <Radio value="marcapasos">Marcapasos</Radio>
        </Radio.Group>
      </Form.Item>
      <span>
        <b>
          Si el paciente tiene indicación a dispositivo, pero no se implantó
        </b>
      </span>
      <Form.Item name="SiElPacienteTieneIndicacionADispositivoPeroNoSeImplanto">
        <Radio.Group
          className="radioContainer"
          name="SiElPacienteTieneIndicacionADispositivoPeroNoSeImplanto"
          onChange={onChangeField}
        >
          <Radio value="faltaExistenciasDelDispositivoEnElHospital">
            Falta existencia del dispositivo en el hospital
          </Radio>
          <Radio value="negativaDelPaciente">Negativa del paciente</Radio>
          <Radio value="elPacienteNoTieneRecursos">
            El paciente no tiene recursos
          </Radio>
          <Radio value="negativaPorParteDeSGM">Negativa por parte de SGM</Radio>
          <Radio value="pronosticoDeMenosDe1AnoDeVida">
            Pronóstico de menos de 1 año de vida
          </Radio>
          <Radio value="contraindicacionesAlUsoDelDispositivo">
            Contraindicaciones al uso del dispositivo
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Otro"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 6 }}
        name="SiElPacienteTieneIndicacionADispositivoPeroNoSeImplantoOtro"
      >
        <Input
          name="SiElPacienteTieneIndicacionADispositivoPeroNoSeImplantoOtro"
          onChange={onChangeField}
        />
      </Form.Item>
      <span>
        <b>Asistencia mecánica circulatoria</b>
      </span>
      <Form.Item name="asistenciaMecanicaCirculatoria">
        <Radio.Group
          name="asistenciaMecanicaCirculatoria"
          onChange={onChangeField}
        >
          <Radio value="DAVI">DAVI</Radio>
          <Radio value="ECMO">ECMO</Radio>
          <Radio value="intermacs">Intermacs</Radio>
          <Radio value="SVE">SVE</Radio>
        </Radio.Group>
      </Form.Item>
      <span>
        <b>Trasplante cardiaco</b>
      </span>
      <Form.Item name="trasplanteCardiaco">
        <Radio.Group name="trasplanteCardiaco" onChange={onChangeField}>
          <Radio value="Si">Si</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}

export default IntervencionesDuranteLaHospitalizacion;
