import React from "react";

import { Form, InputNumber, Input, DatePicker } from "antd";

function GeneralDataHospitalizacion({
  onChangeField,
  onChangeNumber,
  onChangeDate,
  state,
}) {
  return (
    <div>
      <Form.Item
        label="Número de hospitalización"
        name="numeroDeHospitalizacion"
      >
        <InputNumber disabled={true} />
      </Form.Item>
      <Form.Item label="ID" name="cuatroUltimosDigitosDeLaCedula">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Fecha de nacimiento" name="nacimiento">
        <DatePicker format="DD-MM-YYYY" disabled={true} />
      </Form.Item>
    </div>
  );
}

export default GeneralDataHospitalizacion;
