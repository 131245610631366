import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const Vasodilatadores = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Nitroglicerina"
                checked={state.nitroglicerinaCheck}
                input={state.nitroglicerina}
                date={state.nitroglicerinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nitroglicerina"
                nameCheck="nitroglicerinaCheck"
                nameDate="nitroglicerinaDate"
            />
            <Medicamento
                labelName="Dinitrato de
                isosorbida"
                checked={state.dinitratoDeIsosorbidaCheck}
                input={state.dinitratoDeIsosorbida}
                date={state.dinitratoDeIsosorbidaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dinitratoDeIsosorbida"
                nameCheck="dinitratoDeIsosorbidaCheck"
                nameDate="dinitratoDeIsosorbidaDate"
            />
            <Medicamento
                labelName="Nitroprusiato"
                checked={state.nitroprusiatoCheck}
                input={state.nitroprusiato}
                date={state.nitroprusiatoDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nitroprusiato"
                nameCheck="nitroprusiatoCheck"
                nameDate="nitroprusiatoDate"
            />
            <Medicamento
                labelName="Nesiritida"
                checked={state.nesiritidaCheck}
                input={state.nesiritida}
                date={state.nesiritidaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="nesiritida"
                nameCheck="nesiritidaCheck"
                nameDate="nesiritidaDate"
            />
            <Medicamento
                labelName="Noradrenalina"
                checked={state.noradrenalinaCheck}
                input={state.noradrenalina}
                date={state.noradrenalinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="noradrenalina"
                nameCheck="noradrenalinaCheck"
                nameDate="noradrenalinaDate"
            />
            <Medicamento
                labelName="Epinefrina"
                checked={state.epinefrinaCheck}
                input={state.epinefrina}
                date={state.epinefrinaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="epinefrina"
                nameCheck="epinefrinaCheck"
                nameDate="epinefrinaDate"
            />
        </>
    );
};

export default Vasodilatadores;