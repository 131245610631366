import React from "react";

import { Checkbox, Form, Input } from "antd";

const EtiologiaIC = ({ onChangeCheck, onChangeField, state }) => {
  return (
    <div className="checkBoxContainer">
      <div>
        <Form.Item wrapperCol={{ span: 17 }}>
          <Checkbox
            checked={state.abusoDeSustanciasToxicasDeUsoRecreativoCheck}
            onChange={onChangeCheck}
            name="abusoDeSustanciasToxicasDeUsoRecreativoCheck"
          >
            Abuso de sustancias tóxicas de uso recreativo
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.metalesPesadosCheck}
            onChange={onChangeCheck}
            name="metalesPesadosCheck"
          >
            Metales pesados
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.medicamentosCheck}
            onChange={onChangeCheck}
            name="medicamentosCheck"
          >
            Medicamentos
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.radiacionCheck}
            onChange={onChangeCheck}
            name="radiacionCheck"
          >
            Radiación
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.enfermedadDeChagasCheck}
            onChange={onChangeCheck}
            name="enfermedadDeChagasCheck"
          >
            Enfermedad de chagas
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.enfermedadDeTiroidesYParatiroidesCheck}
            onChange={onChangeCheck}
            name="enfermedadDeTiroidesYParatiroidesCheck"
          >
            Enfermedades de tiroides y paratiroides
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.diabetesCheck}
            onChange={onChangeCheck}
            name="diabetesCheck"
          >
            Diabetes
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 17 }}>
          <Checkbox
            checked={state.comunicacionInterauricularOInterventricularCheck}
            onChange={onChangeCheck}
            name="comunicacionInterauricularOInterventricularCheck"
          >
            Comunicación interauricular o interventricular
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.pericarditisConstrictivaCheck}
            onChange={onChangeCheck}
            name="pericarditisConstrictivaCheck"
          >
            Pericarditis constrictiva
          </Checkbox>
        </Form.Item>
      </div>
      <div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.arritmiasVentricularesCheck}
            onChange={onChangeCheck}
            name="arritmiasVentricularesCheck"
          >
            Arritmias ventriculares
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.disfuncionesDelNoduloSinusalCheck}
            onChange={onChangeCheck}
            name="disfuncionesDelNoduloSinusalCheck"
          >
            Disfunciones del nódulo sinusal
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.arritmiasAuricularesCheck}
            onChange={onChangeCheck}
            name="arritmiasAuricularesCheck"
          >
            Arritmias auriculares
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 20 }}>
          <Checkbox
            checked={
              state.enfermedadesRelacionadasConLaGestacionYElPeripartoCheck
            }
            onChange={onChangeCheck}
            name="enfermedadesRelacionadasConLaGestacionYElPeripartoCheck"
          >
            Enfermedades relacionadas con la gestación y el periparto
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.deficienciasEnCarnitinaLCheck}
            onChange={onChangeCheck}
            name="deficienciasEnCarnitinaLCheck"
          >
            Deficiencias en carnitina-L
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.MCHCheck}
            onChange={onChangeCheck}
            name="MCHCheck"
          >
            MCH
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={state.MCDCheck}
            onChange={onChangeCheck}
            name="MCDCheck"
          >
            MCD
          </Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.derramePericardicoCheck}
            onChange={onChangeCheck}
            name="derramePericardicoCheck"
          >
            Derrame pericárdico
          </Checkbox>
        </Form.Item>
      </div>
      <div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.fibroelastosisEndomiocardicaCheck}
            onChange={onChangeCheck}
            name="fibroelastosisEndomiocardicaCheck"
          >
            Fibroelastosis endomiocárdica
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.VINoCompactadoCheck}
            onChange={onChangeCheck}
            name="VINoCompactadoCheck"
          >
            VI no compactado
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.miocardiopatiaRestrictivaCheck}
            onChange={onChangeCheck}
            name="miocardiopatiaRestrictivaCheck"
          >
            Miocardiopatia restrictiva
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.valvulopatiaMitralCheck}
            onChange={onChangeCheck}
            name="valvulopatiaMitralCheck"
          >
            Valvulopatía mitral
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.valvulopatiaAorticaCheck}
            onChange={onChangeCheck}
            name="valvulopatiaAorticaCheck"
          >
            Valvulopatía aortica
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.valvulopatiaTricuspideaCheck}
            onChange={onChangeCheck}
            name="valvulopatiaTricuspideaCheck"
          >
            Valvulopatía tricúspidea
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Checkbox
            checked={state.valvulopatiaPulmonarCheck}
            onChange={onChangeCheck}
            name="valvulopatiaPulmonarCheck"
          >
            Valvulopatía pulmonar
          </Checkbox>
        </Form.Item>

        <Input.Group compact>
          <Form.Item>
            <Checkbox
              checked={state.otroEtiologiaCheck}
              onChange={onChangeCheck}
              name="otroEtiologiaCheck"
            >
              Otro
            </Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }} name="otroEtiologia">
            <Input
              className="etiologiaotro"
              placeholder="Otro"
              name="otroEtiologia"
              disabled={!state.otroEtiologiaCheck}
              onChange={onChangeField}
            />
          </Form.Item>
        </Input.Group>
      </div>
    </div>
  );
};

export default EtiologiaIC;
