import React from "react";

import { Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import "./UsuariosHeader.css";

const UsuariosHeader = ({ displayUserForm }) => {
  return (
    <div className="UsuariosHeader">
      <div>
        <h1>Clinica</h1>
      </div>
      <Button onClick={displayUserForm}>
        <UserAddOutlined />
        Nuevo Usuario
      </Button>
    </div>
  );
};

export default UsuariosHeader;
