import React from "react";

import { Form, DatePicker, Radio } from "antd";

const CriterioDeInclusion = ({ onChangeField, onChangeDate, state }) => {
  return (
    <>
      <Form.Item label="Fecha de diagnóstico IC" name="fechaDeDiagnosticoIC">
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(date, dateString) =>
            onChangeDate(date, dateString, "fechaDeDiagnosticoIC")
          }
        />
      </Form.Item>
      <Form.Item label="Tipo de IC" name="tipoDeIC">
        <Radio.Group onChange={onChangeField} name="tipoDeIC">
          <Radio value="isquemica">Isquémica</Radio>
          <Radio value="noIsquemica">No squémica</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Clasificación de lC" name="clasificacionDeIC">
        <Radio.Group onChange={onChangeField} name="clasificacionDeIC">
          <Radio value="NYHAI">NYHA I</Radio>
          <Radio value="NYHAII">NYHA II</Radio>
          <Radio value="NYHAIII">NYHA III</Radio>
          <Radio value="NYHAIIII">NYHA IIII</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="ETAPA ACC, AHC" name="etapaACCAHC">
        <Radio.Group onChange={onChangeField} name="etapaACCAHC">
          <Radio value="A">A</Radio>
          <Radio value="B">B</Radio>
          <Radio value="C">C</Radio>
          <Radio value="D">D</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default CriterioDeInclusion;
