
import React from "react";

import Medicamento from "../../components/forms/Medicamento";

const AgentesInotropicos = ({onChangeField, onChangeDate, onChangeCheck, state}) => {
    return(
        <>
            <Medicamento
                labelName="Dobutamina"
                checked={state.dobutaminaCheck}
                input={state.dobutamina}
                date={state.dobutaminaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dobutamina"
                nameCheck="dobutaminaCheck"
                nameDate="dobutaminaDate"
            />
            <Medicamento
                labelName="Dopamina"
                checked={state.dopaminaCheck}
                input={state.dopamina}
                date={state.dopaminaDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="dopamina"
                nameCheck="dopaminaCheck"
                nameDate="dopaminaDate"
            />
            <Medicamento
                labelName="Levosimendán"
                checked={state.levosimendanCheck}
                input={state.levosimendan}
                date={state.levosimendanDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="levosimendan"
                nameCheck="levosimendanCheck"
                nameDate="levosimendanDate"
            />
            <Medicamento
                labelName="Inhibidores de la
                fosfodiesterasa III"
                checked={state.inhibidoresDeLaFosfodiesterasaIIICheck}
                input={state.inhibidoresDeLaFosfodiesterasaIII}
                date={state.inhibidoresDeLaFosfodiesterasaIIIDate}
                onChangeField={onChangeField}
                onChangeDate={onChangeDate}
                onChangeCheck={onChangeCheck}
                name="inhibidoresDeLaFosfodiesterasaIII"
                nameCheck="inhibidoresDeLaFosfodiesterasaIIICheck"
                nameDate="inhibidoresDeLaFosfodiesterasaIIIDate"
            />
        </>
    );
};

export default AgentesInotropicos;